import React  from 'react';
import {useContext} from 'react'
import {UserContext} from '../../context/UserContext'
import { NumericFormat } from 'react-number-format';

const Transactions = () => {

    const {text, user, profile} = useContext(UserContext);



    const sum = profile.reduce((a,v) =>  a = a + v.profile_total_amount , 0 );

    const totalAmount = profile.reduce((accumulator, item) => {
        const investmentSum = item.investments.reduce((investmentAcc, item) => {
            const orderSum = item.orders.reduce((orderAcc, item) => {   
                const transSum = item.transactions.reduce((transAcc, transaction) => {
                    return transAcc + transaction.amount;
                }, 0);
                return orderAcc + transSum;
            }, 0);
            return investmentAcc + orderSum;
        }, 0);
        return accumulator + investmentSum;
      }, 0);

     
      const sortedTransactions = profile
      .flatMap((profile) =>
        profile.investments.flatMap((investment) =>
          investment.orders.flatMap((order) => order.transactions)
        )
      )
      .sort((b, a) => {
        if (a.order_date && b.order_date) {
          return a.order_date.localeCompare(b.order_date);
        }
        // Handle cases where order_date is undefined
        return 0;
      });
      
    return (

    
        



<div className="card card-small hide-medium">
    <div className="card-body">
        <h3 className="text-left">Transacties</h3>

        {totalAmount == 0 && ( <><h6 className="w-100">Nog geen transacties bevestigd.</h6> <p className="muted small">Wel betaald? Geen zorgen. Dit kan even duren.</p></>)}

       <div className="transactions">

       
        
       {sortedTransactions.map((transaction) => (
                <>
                {transaction.amount != 0 && (
                <>
                {transaction.status === 'paid' ?
                <>
                <div key={transaction.order_id} className="transaction w-100 thumb d-flex aic">
                    <div className="tt_icon paid">
                        <h3><i className="bi bi-plus"></i></h3>
                    </div>
                    <div className="tt_info w-100">
                        <div className="d-flex justify-content-between">
                        <h6>€<NumericFormat value={transaction.amount.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h6>
                        <h6 className="small muted">{transaction.preview_date}</h6>
                        </div>
                        <p className="muted small" >{transaction.name}</p>
                    </div>
                   
                </div>
                {/*<hr className="little-line"/>*/}
                </>
                :
                <>
                <div key={transaction.order_id} className="transaction w-100 thumb d-flex aic">
                    <div className="tt_icon failed">
                        <h3><i class="bi bi-x"></i></h3>
                    </div>
                    <div className="tt_info w-100">
                    <div className="d-flex justify-content-between">
                        <h6><s>€<NumericFormat value={transaction.amount.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></s></h6>
                        <h6 className="small muted">{transaction.preview_date}</h6>
                    </div>
                        <p className="muted small" >Online betaling - {transaction.status === 'expired' ? 'Verlopen' : 'Afgebroken' } </p>
                    </div>
                </div>
               {/*<hr className="little-line"/>*/}
                </>
                }
                </>
                )}
                </>
            ))}
       

        </div>

    
        
       
    </div>
</div>



    )


}

export default Transactions;