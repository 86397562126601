
import Nav from 'react-bootstrap/Nav';
import React from "react";
import { Link } from 'react-router-dom';




const FundHeader = ({ filteredFund }) => {
return(
    <div className="card card-full fund-header">
        <div className="card-body">

        <div class="card-top">
            <div class="fund-heading">
                <div class="tt_icon large hide-phone" style={{backgroundImage: `url("https://admin.capler.nl/public_assets/${filteredFund.logo}")`}}>
            </div>
            <div class="left">
                <h3 class="text-left">{filteredFund.name}</h3>
                <p class="sub">In beheer van {filteredFund.manager}</p>
            </div>
            </div>
            <Link target="_blank" class="btn hide-phone" to={filteredFund.website}>
                Bezoek website <i class="bi right bi-arrow-up-right"></i>
            </Link>
        </div>

           

            {/*
            <Nav variant="pills" className="flex-row">

            <Link className="nav-link" to={"/funds/" + filteredFund.public_id + "/overview"}>Overzicht</Link>
            <Link className="nav-link" to={"/funds/" + filteredFund.public_id + "/performance"}>Prestatie</Link>
             <Link className="nav-link" to={"/funds/" + filteredFund.public_id + "/portfolio"}>Portefeuille</Link>
         
            </Nav>
            */}

        </div>
    </div>
)
}

export default FundHeader;