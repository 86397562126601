import Topbar from '../components/dashboard/Topbar';
import Sidebar from '../components/dashboard/Sidebar';
import Board from '../components/dashboard/Board';
import React from "react";



const Home = () => {

    

    return (

        

        <div id="dashboard">

            <Topbar/>   
            <Sidebar/> 
            <Board/>   
            
        </div>

    )
}

export default Home;
