import React  from 'react';
import {useContext, useState} from 'react'
import {UserContext} from '../context/UserContext';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Banner from './Banner';
import { Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

const Verification = () => {
    const {verificationUser, wait} = useContext(UserContext);
    const [errMsg, setErrMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [hasPlenty, setHasPlenty] = useState(false);
    const [hasCapital, setHasCapital] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasDigit, setHasDigit] = useState(false);
    const urlString = window.location.pathname.replace('/verification/', '');
    const [formData, setFormData] = useState({
        password:'',
        password_repeat:'',
        veri_string:urlString
    });

    const navigate = useNavigate();

    const onChangeInput = (e) => {
        const value = e.target.value;
        const hasPlentyLetter = value.length >= 7;
        const hasCapitalLetter = /[A-Z]/.test(value);
        const hasNumberLetter = /[0-9]/.test(value);
        const hasDigitLetter = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);
       
            setHasPlenty(hasPlentyLetter);
            setHasCapital(hasCapitalLetter);
            setHasNumber(hasNumberLetter);
            setHasDigit(hasDigitLetter);
      
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        })
    }

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if(!Object.values(formData).every(val => val.trim() !== '')){
            setSuccessMsg(false);
            setErrMsg('Please Fill in all Required Fields!');
            return;
        }

        const data = await verificationUser(formData);
        if(data.success){
            e.target.reset();
            setSuccessMsg('Uw wachtwoord is succesvol ingesteld. U wordt over 5 seconden doorverwezen naar het loginscherm.');
            setErrMsg(false);
            await timeout(5000);
            navigate('/login');
        }
        else if(!data.success && data.message){
            setSuccessMsg(false);
            setErrMsg(data.message);
        }
        
    }

    return (
        <div className="splitter d-flex">
        <Banner/>
        
        <div className="split form">
        <div className="auth">
            <h3>Wachtwoord instellen</h3>
            <Form onSubmit={submitForm}>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1"><i className="bi bi-lock"></i></InputGroup.Text> 
                    <Form.Control type="password" name="password" onChange={onChangeInput} placeholder="Nieuw wachtwoord" id="password" value={formData.password} required />
                </InputGroup>
                <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><i className="bi bi-lock"></i></InputGroup.Text> 
                    <Form.Control type="password" name="password_repeat" onChange={onChangeInput} placeholder="Herhaal wachtwoord" id="password_repeat" value={formData.password_repeat} required />
                </InputGroup>
                <p className="form-disc">
                Uw wachtwoord moet bestaan uit minimaal:
                    </p>
                    {hasPlenty ? 
                    <p className="muted purple small form-disc"><i class="bi bi-check-circle mr-2"></i> 8 tekens, waarvan:</p>
                    :
                    <p className="muted small form-disc"><i class="bi bi-circle mr-2"></i> 8 tekens, waarvan:</p>
                    }
                    {hasCapital ? 
                    <p className="muted purple small form-disc"><i class="bi bi-check-circle mr-2"></i> 1 hoofdletter</p>
                    :
                    <p className="muted small form-disc"><i class="bi bi-circle mr-2"></i> 1 hoofdletter</p>
                    }
                    {hasNumber ? 
                    <p className="muted purple small form-disc"><i class="bi bi-check-circle mr-2"></i> 1 cijfer</p>
                    :
                    <p className="muted small form-disc"><i class="bi bi-circle mr-2"></i> 1 cijfer</p>
                    }
                    {hasDigit ? 
                    <p className="muted purple small form-disc"><i class="bi bi-check-circle mr-2"></i> 1 speciaal teken</p>
                    :
                    <p className="muted small form-disc"><i class="bi bi-circle mr-2"></i> 1 speciaal teken</p>
                    }
                <input hidden type="text" name="veri_string" onChange={onChangeInput} id="veri_string" value={formData.veri_string}  disabled />
                {successMsg && 
                <>
                <div className="success-msg">{successMsg}</div>
                <Link className="btn btn-primary" to='/login'>Naar inloggen</Link>
                </>
                }
                {errMsg && <div className="err-msg">{errMsg}</div>}
                {!successMsg && <button className="btn btn-primary" type="submit" disabled={wait}>Stel wachtwoord in</button>}
                
            </Form>
        </div>
        </div>
        </div>

    )
}

export default Verification;
