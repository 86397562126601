import React from "react";


const Banner = () => {

   

    return (

    <div className="split banner">
        <h1>Private equity <span>geopend</span></h1>

        <svg id="cap-stripe" viewBox="0 0 657.99 79.16">
            
            <path class="cls-1" d="M5.66,79.16c-2.87,0-5.33-2.17-5.63-5.08-.33-3.11,1.93-5.9,5.05-6.23L651.73,.03c3.12-.33,5.9,1.93,6.23,5.05,.33,3.11-1.93,5.9-5.05,6.23L6.26,79.13c-.2,.02-.4,.03-.6,.03Z"/>
        </svg>
        {/* 
        <svg id="banner-bg" viewBox="0 0 55.2 39.7" >
            <g>
                <g>
                    <path className="st0" d="M54.5,31.1L37.6,1.9c-1.5-2.6-5.2-2.6-6.7,0L20.1,20.5l9.4,16.3c1,1.8,2.9,2.9,5,2.9h14.9
                            C53.9,39.7,56.7,34.9,54.5,31.1"/>
                </g>
                <g>
                    <path className="st0" d="M5.8,39.7L5.8,39.7c2.1,0,4-1.1,5-2.9l9.4-16.3l-3.3-5.7c-1.5-2.6-5.2-2.6-6.7,0L0.8,31.1
                            C-1.4,34.9,1.3,39.7,5.8,39.7"/>
                </g>
            </g>   
        </svg>
        */}
    </div>

)
}

export default Banner;