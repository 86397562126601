import {useState,useContext,useEffect} from 'react';
import {UserContext} from '../context/UserContext'
import Topbar from '../components/dashboard/Topbar';
import Sidebar from '../components/dashboard/Sidebar';
import React from "react";
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

const Inbox = () => {

    const {user, text, notifications} = useContext(UserContext);

    let activeStyle = {
        background: "#eaeaea",
        color: "black"
      };
    
    const numDescending = [...notifications].sort((a, b) => b.order_date - a.order_date);
    const [notification, setNotifications] = useState([]);
    const [preview, setPreview] = useState(true);
   
    function NoneSelected() {
        return <h4 className="center muted">{text.no_message_selected}</h4>;
      }

      useEffect(() => {
        const notificationSelected = window.location.pathname.replace('/inbox/', '');
        if (notificationSelected.length > '7') {
        setPreview(false);
        } else {
        setPreview(true);  
        } 
      });
      
      function SelectedNotification() {
        const notificationSelected = window.location.pathname.replace('/inbox/', '');
        if (notificationSelected.length > '7') {
            return ;
            setPreview(false);
        } 
        return <NoneSelected />;
      }



  function loadNotification(e) {
    const item = e.target.value;
    setNotifications([...notification]);
  }



    
    

    return (

        

        <div id="dashboard">

            <Topbar/>   
            <Sidebar/> 

           

            <div className="board inboxy">
            {preview && (
            <div className="card card-small overview show-medium">
                <div className="card-body">
                    <h4 className="text-left">{text.messages}</h4>

                    
                    
                    {numDescending.map((notifications, idx) => { 

                  
                        
                    return(
                        <NavLink style={({ isActive }) => isActive ? activeStyle : undefined} className="nav-link" key={idx} to={notifications.string} onClick={loadNotification}>
                        <h6 className="h-grow">{notifications.subject}</h6>
                        <h6 className="small muted">{notifications.preview_date}</h6>
                        <p className="small muted">{text.dear} {user.first_name}, {notifications.body}</p>
                        
                        </NavLink>
                        )
                    })}
                   
                    
                </div>
            </div>
            )}
            {!preview && (
            <div className="card card-large full-height preview show-medium">
                <div className="card-body">
                    <div className="card-top">
                <Link className="btn ml-0 mb-3" to="/inbox/"><i class="bi bi-arrow-left"></i> Terug naar inbox</Link>
                </div>
                {notifications.filter(notification => notification.string.includes(window.location.pathname.replace('/inbox/', ''))).map(filteredNotification => {

                    if (filteredNotification >= '0') return(
                    <div className="w-100" key={filteredNotification.string}>
                    <div className="d-flex justify-content-between mb-3">
                        <h6 className="small muted">{filteredNotification.sender}</h6>
                        <h6 className="small muted">{filteredNotification.display_date}</h6>
                    </div>
                    <h4 className="mt-3">{filteredNotification.subject}</h4>
                    <p>{text.dear} {user.first_name},</p>
                    <p>{filteredNotification.body}</p>
                    <p>{text.best},<br/>{filteredNotification.sender}</p>
                        
                    </div>
                    ) 
                   
   
               })}

                <SelectedNotification />
            
                </div>
            </div>
            )}



            <div className="card card-small overview hide-medium">
                <div className="card-body">
                    <h4 className="text-left">{text.messages}</h4>

                    
                    
                    {numDescending.map((notifications, idx) => { 

                  
                        
                    return(
                        <NavLink style={({ isActive }) => isActive ? activeStyle : undefined} className="nav-link" key={idx} to={notifications.string} onClick={loadNotification}>
                        <h6 className="h-grow">{notifications.subject}</h6>
                        <h6 className="small muted">{notifications.preview_date}</h6>
                        <p className="small muted">{text.dear} {user.first_name}, {notifications.body}</p>
                        
                        </NavLink>
                        )
                    })}
                   
                    
                </div>
            </div>
            
            <div className="card card-large full-height preview hide-medium">
                <div className="card-body">
                {notifications.filter(notification => notification.string.includes(window.location.pathname.replace('/inbox/', ''))).map(filteredNotification => {

                    if (filteredNotification >= '0') return(
                    <div className="w-100" key={filteredNotification.string}>
                    <div className="d-flex justify-content-between  mb-3">
                        <h6 className="small muted">{filteredNotification.sender}</h6>
                        <h6 className="small muted">{filteredNotification.display_date}</h6>
                    </div>
                    <h4>{filteredNotification.subject}</h4>
                    <p>{text.dear} {user.first_name},</p>
                    <p>{filteredNotification.body}</p>
                    <p>{text.best},<br/>{filteredNotification.sender}</p>
                        
                    </div>
                    ) 
                   
   
               })}

                <SelectedNotification />
            
                </div>
            </div>
            



            </div>

            
            
            
        </div>

    )
}

export default Inbox;