import React from "react";
import {useState,useContext} from 'react';
import {UserContext} from '../../context/UserContext'
import { NavLink } from 'react-router-dom';

const Navbar = () => {

    const {text, user} = useContext(UserContext);

    let activeStyle = {
        background: "#eaeaea",
        color: "black"
      };

    return (

<div className="card card-small sidecard">
<div className="card-body">
    <h4 className="text-left">{text.settings}</h4>

    <NavLink style={({ isActive }) => isActive ? activeStyle : undefined} className="nav-link" to='/settings/account' onClick=''>
        <h6>Account</h6>
        <h6></h6>
        <p className="small muted" >Beheer uw account</p>
    </NavLink>
    {user.has_profiles === 'yes' && (
    <NavLink style={({ isActive }) => isActive ? activeStyle : undefined} className="nav-link" to='/settings/profiles' onClick=''>
        <h6>Profielen</h6>
        <h6></h6>
        <p className="small muted" >Beheer uw profielen</p>
    </NavLink>
    )}
    {/*
    {user.has_recurring === 'yes' && (
    <NavLink style={({ isActive }) => isActive ? activeStyle : undefined} className="nav-link" to='/settings/recurring' onClick=''>
        <h5>Maandelijks beleggen</h5>
        <h6></h6>
        <p className="muted" >Beheer uw lopende incasso's</p>
    </NavLink>
    )}
    */}
     <NavLink style={({ isActive }) => isActive ? activeStyle : undefined} className="nav-link" to='/settings/filesharing' onClick=''>
        <h6>Documenten</h6>
        <h6></h6>
        <p className="small muted" >Deel uw documenten</p>
    </NavLink>
</div>
</div>

)
}

export default Navbar;