import React  from 'react';
import {useContext} from 'react'
import {UserContext} from '../../context/UserContext'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert';
import Contract from '../../documents/Contract'
import { NumericFormat } from 'react-number-format';

const Signature = (props) => {

    const {profile, text} = useContext(UserContext);

    const selectedProfile = localStorage.getItem('selectedProfile');

    const brutAmount = localStorage.getItem('brutAmount');

    const selectedMethod = localStorage.getItem('selectedMethod');

    const selectedRecurring = localStorage.getItem('selectedRecurring');

    const setMonthlyAmount = localStorage.getItem('setMonthlyAmount');

    

 

  

    

    return (
        
<>
                <h4 className="text-left">Een laatste check</h4>
              
                

                <div>
        {profile.filter(profile => profile.profile_id === selectedProfile).map(filteredProfile => (
   <>
   <p>Uw inschrijvingscontract staat klaar. Check of onderstaande gegevens kloppend zijn.</p>
  
    <Form className="extra-large">
    
      {['checkbox'].map((type) => (
        <div key={type} className="mb-3 mt-3">
          <Form.Check type={type} id={`check-api-${type}`}>
            <Form.Check.Input 
                required
                value={props.profileIsChecked}
                type={type} 
                onChange={props.handleProfile}
                isValid={props.profileIsChecked} 
            />
            <Form.Check.Label>{"Ik participeer als "+(filteredProfile.profile_name)+'.'} </Form.Check.Label>
            
          </Form.Check>

          {localStorage.getItem('selectedMethod') && 
          <Form.Check type={type} id={`check-api-${type}`}>
            <Form.Check.Input 
                required
                value={props.methodIsChecked}
                type={type} 
                onChange={props.handleMethod}
                isValid={props.methodIsChecked} />
            <Form.Check.Label>{"Ik beleg "+(selectedMethod === 'regular' ? 'niet volgens aanvullende voorwaarden lijfrente.' : 'voor mijn pensioen via een lijfrente en ben goed ingelicht over de fiscale voor- en nadelen.')} </Form.Check.Label>
           
          </Form.Check>
            }
          {localStorage.getItem('brutAmount') && 
          <Form.Check type={type} id={`check-api-${type}`}>
            <Form.Check.Input 
                required
                value={props.amountIsChecked}
                type={type} 
                onChange={props.handleAmount}
                isValid={props.amountIsChecked} />
            <Form.Check.Label>{"Ik beleg "+(selectedRecurring && selectedRecurring === 'yes' ? 'maandelijks €' : 'nu eenmalig €')}<NumericFormat value={brutAmount} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} />.</Form.Check.Label>
           
          </Form.Check>
          }
          <Form.Check type={type} id={`check-api-${type}`}>
            <Form.Check.Input 
                required
                value={props.contractIsChecked}
                type={type} 
                onChange={props.handleContract}
                isValid={props.contractIsChecked} />
            <Form.Check.Label>{"Ik heb al mijn gegevens naar waarheid ingevuld."} </Form.Check.Label>
           
          </Form.Check>
        </div>
      ))}
    </Form>

    
    

    </>
  ))}
</div>

</>
        
    )
}

export default Signature;


