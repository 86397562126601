import {useState,useContext,useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import React  from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/UserContext'
import Topbar from '../../components/dashboard/Topbar';
import Sidebar from '../../components/dashboard/Sidebar';
import Navbar from './Navbar';
import { confirm } from "../../components/PopUp";
import { Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import axios from 'axios'


export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
  });


const Recurring = () => {


    const {user, text, profile, loggedInCheck} = useContext(UserContext);
    const [wait, setWait] = useState(false);
    const navigate = useNavigate();

    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
   
    const handleOnClick = async (pid) => {
        if (await confirm("Weet je zeker dat je dit profiel wil verwijderen? Alle bijbehorende gegevens gaan verloren.")) {
            setWait(true);
            try{
                const loginToken = localStorage.getItem('loginToken');
                Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
                const {data} = await Axios.post('profile/delete.php',{
                  profile_id: pid
                }).then(response => response.data);
        
                
                
                
                await loggedInCheck();
                
                setWait(false);
                
                
          
            }
            catch(err){
                setWait(false);
                return {success:0, message:'Server Error!'};
            }
        } else {
         
        }
      }

      const [errMsg, setErrMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [modalData, setModalData] = useState({
        cor_phone: "",
        cor_street: "",
        cor_no: "",
        cor_zip: "",
        cor_city: "",
        cor_country: "",

      });

      const [amountValid, setAmountValid] = useState(false);
      const [dateValid, setDateValid] = useState(false);

      const [isValid, setIsValid] = useState(true);
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setModalData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };

      const [endChecked, setEndChecked] = useState(false);

  const handleCheckboxChange = () => {
    setEndChecked(!endChecked);
    if (!endChecked) {
        setIsValid(false);
    } else {
        setIsValid(true);
    }
  };

  const handleAmountChange = (min_amount, e) => {
    const inputAmount = e.target.value;
    const minAmount = min_amount;

    if (inputAmount < minAmount) {
        setAmountValid(true);
        setIsValid(false);
    } else {
        setAmountValid(false);
        setIsValid(true);
    }
};

      const handleDateChange = (min_end_date, e) => {
        const inputDate = new Date(e.target.value);
        const minDate = new Date(min_end_date);
    
        if (inputDate < minDate) {
            setDateValid(true);
            setIsValid(false);
        } else {
            setDateValid(false);
            setIsValid(true);
        }
    };
    
   
    const editRecurring = async (e) => {
        e.preventDefault();
        setWait(true);
            try{
                const loginToken = localStorage.getItem('loginToken');
                Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
                const {data} = await Axios.post('profile/update.php',{
                  profile_id: modalData.profile_id,
                 
                }).then(response => response.data);
        
                
                
                
                await loggedInCheck();
                
                setWait(false);
                
                
          
            }
            catch(err){
                setWait(false);
                return {success:0, message:'Server Error!'};
            }
    }
    

    return (

    <>

    <div id="dashboard">

        <Topbar/>   
        <Sidebar/> 
        <div className="board settingsy">
            <Navbar/>
            <div className="card card-large settings">
                <div className="card-body">
                    <h6 className="text-left small mb-3 muted">{text.settings}</h6>
                    
<h4 className="text-left">Maandelijks beleggen</h4>
    <p className="settings-intro">Hier kunt u de bedragen van uw lopende incasso's wijzigen of uw lopende contracten opzeggen.</p>
                    {profile.map((profile, index) => (
                     <>
                     {profile.has_reg_recurring === 'yes' && (
                        <div className="file">
                            <div className="d-flex aic mw-60">
                                {profile.pers_bus === 'pers' ?
                                <h3><i className="bi muted bi-person"></i> </h3>
                                : 
                                <h3><i className="bi muted bi-briefcase"></i> </h3>
                                }
                                <div>
                                <h6>{profile.profile_name}</h6>
                                {profile.pers_bus === 'pers' ?
                                <p className="muted small">Privé beleggen</p>
                                : 
                                <p className="muted small">Zakelijk beleggen</p>
                                }
                                
                                </div>
                                
                            </div>   
                        
                            <p></p>
                            
                            <Link onClick={() => {handleShow(); setModalData(profile);}} className="btn">Voorkeuren</Link>


                        <h6></h6>
                        
                        </div>
                        )}
                        {profile.has_pen_recurring === 'yes' && (
                        <div className="file">
                            <div className="d-flex aic mw-60">
                                {profile.pers_bus === 'pers' ?
                                <h3><i className="bi muted bi-person"></i> </h3>
                                : 
                                <h3><i className="bi muted bi-briefcase"></i> </h3>
                                }
                                <div>
                                <h6>{profile.profile_name}</h6>
                               
                                <p className="muted small">Pensioen beleggen</p>
                                
                                
                                </div>
                                
                            </div>   
                        
                            <p></p>
                            
                            <Link onClick={() => {handleShow(); setModalData(profile);}} className="btn">Voorkeuren</Link>


                        <h6></h6>
                        
                        </div>
                        )}
                        </>
                    ))}

                </div>
            </div>
    </div>

   </div>

{modalData && (
<Modal size="lg" show={show} onHide={handleClose} pid={modalData.profile_id}>
<Modal.Header closeButton>
  <Modal.Title>{modalData.profile_name}
  
  </Modal.Title>
</Modal.Header>
<Form onSubmit={editRecurring}>
<Modal.Body>

    
    
    {modalData.investments?.map((investment, index) => (
        <>
           
                {investment.recurring === '1' && (
                    <>

                    {investment.investment_end_date ? 
                    <>
                    <Form.Group className="mb-5" controlId="monthlyAmount">
                    <h5 className="">Maandelijkse belegging</h5>
                    <Form.Text className="text-muted">
                    Maandelijks beleggen is mogelijk vanaf €100,-
                    </Form.Text>
                    <span className="input-symbol-euro">
                    <NumericFormat 
                    onChange={(e)=>{handleInputChange(e.target.value, 100)}}
                    name='qn_amount_year' 
                    className="form-control mt-3"  
                    decimalScale={2} 
                    min={100}
                    value={investment.recurring_amount}
                    decimalSeparator={text.decimal_seperator} 
                    allowedDecimalSeparators={text.decimal_seperator} 
                    allowLeadingZeros 
                    required
                    thousandSeparator={text.thousand_seperator} 
                    valueAsNumber
                    disabled={true}
                    />
                    </span>
                     </Form.Group>
                    <Alert variant="warning">
                        Deze belegging wordt opgezegd per {investment.investment_end_date}.
                     </Alert>
                     </>
                    :
                    <>
                    <Form.Group className="mb-5" controlId="monthlyAmount">
                    <h5 className="">Wijzig uw maandelijkse belegging</h5>
                    <Form.Text className="text-muted">
                        Maandelijks beleggen is mogelijk vanaf €100,-
                    </Form.Text>
                    <span className="input-symbol-euro">
                    <NumericFormat 
                    onChange={(e)=>{handleAmountChange(100, e)}}
                    name='recurring_amount' 
                    className="form-control mt-3"  
                    decimalScale={2} 
                    min={100}
                    value={investment.recurring_amount}
                    decimalSeparator={text.decimal_seperator} 
                    allowedDecimalSeparators={text.decimal_seperator} 
                    allowLeadingZeros 
                    required
                    thousandSeparator={text.thousand_seperator} 
                    valueAsNumber
                    />
                    </span>
                    {amountValid && 
                        <div className="validationWarning">
                            Vul minimaal €100 in.
                        </div>
                    }
                    </Form.Group>

                    <Form.Group className="mb-5" controlId="monthlyAmount">
                    <h5 className="">Automatische incasso</h5>
                    <Form.Text className="text-muted mw-400">
                        Let op! Automatische incasso is niet verplicht voor een maandelijkse belegging. U kunt ook zelf een maandelijkse overboeking instellen in uw bankieren-app.
                    </Form.Text>
                    {investment.direct_debit === 1 ? 
                    <>
                    <Button disabled className="btn btn-sm mt-1 btn-capler-success">
                    <i class="bi bi-check-all"></i> Actief
                  </Button>
                  <Button className="btn btn-black btn-sm ml-2 mt-none">
                   Deactiveer
                </Button>
                </>
                    :
                    <>
                    <Button disabled className="btn btn-sm mt-1 btn-capler-danger">
                    <i class="bi bi-x"></i> Niet actief
                  </Button>
                  <Button className="btn btn-black btn-sm ml-2 mt-none">
                   Activeer
                </Button>
                </>
                }
                   
                        
                    </Form.Group>

                    <Form.Group className="" controlId="monthlyAmount">
                    <h5 className="">Maandelijkse belegging opzeggen</h5>
                    <Form.Text className="text-muted">
                        Deze belegging kan worden opgezegd per {investment.min_end_date}.
                    </Form.Text>
                   
                    <Form.Check
                        type="checkbox"
                        name="end_investment"
                        label="Belegging opzeggen"
                        checked={endChecked}
                        onChange={handleCheckboxChange}
                    />
                    {endChecked && 
                     <Form.Control
                        type="date"
                        name="investment_end_date"
                        onChange={(e) => handleDateChange(investment.min_end_date, e)}
                        className="mt-3"
                    />
                    }
                    {dateValid && 
                        <div className="validationWarning">
                            Kies een datum na {investment.min_end_date}.
                        </div>
                    }
                    </Form.Group>
                    </>
                    }
                    </>
                    )}

        </>
    ))}

   
  
  
  
</Modal.Body>
<Modal.Footer>
  <Button className="btn btn-link" onClick={handleClose}>
    Annuleren
  </Button>
  <Button type="submit" className="btn btn-primary" disabled={!isValid} onClick={handleClose}>
    Wijzigingen opslaan
  </Button>
</Modal.Footer>
</Form>
</Modal>
)}

</>



)
}

export default Recurring;