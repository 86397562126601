import Topbar from '../../components/dashboard/Topbar';
import Sidebar from '../../components/dashboard/Sidebar';
import {useState,useContext,useEffect} from 'react';
import {UserContext} from '../../context/UserContext'
import React from "react";
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { NumericFormat } from 'react-number-format';
import Redirecting from '../../components/Redirecting';
import { confirm } from "../../components/PopUp";
import Form from 'react-bootstrap/Form';
export const Axios = axios.create({
  baseURL: 'https://admin.capler.nl/',
});



const SignatureResponse = () => {


    const {user, text, loggedInCheck} = useContext(UserContext);
    const queryParams = new URLSearchParams(window.location.search);
  const signerStatus = queryParams.get('sh_signerstatus');
  const transactionId = queryParams.get('sh_transactionid');
  const signerId = queryParams.get('sh_signerid');
  const [validated, setValidated] = useState(false);
  const [payment, setPayment] = useState([]);
  const [wait, setWait] = useState(false);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState([]);
  const [checkout, setCheckout] = useState([]);
  const navigate = useNavigate();
  const [selected, setSelected] = useState(false);

  const handleChange = (event) => {
    setSelected(!selected);
  }

  const handleClose = () => {
    localStorage.removeItem('setProgress');
    return '/';
  }

  const handleCancel = async (e) => {
    e.preventDefault();
    if (await confirm("Weet u zeker dat u uw inschrijving wilt annuleren? Alle bijbehorende gegevens gaan verloren.")) {
        setWait(true);
        try{
            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const {data} = await Axios.post('investment/delete.php',{
                investment_id:signerId
            }).then(response => response.data);
    
            
            
            navigate('/');
            await loggedInCheck();
            
            setWait(false);
            
            
      
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Server Error!'};
        }
    } else {
         
    }
  }

  const handleSign = async () => {
    try{
        const loginToken = localStorage.getItem('loginToken');
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
        setIsLoading(true);
        const {data} = await Axios.post('investment/get_signurl.php',{
            investment_id:signerId,
            transaction_id:transactionId,
            transaction_status:signerStatus,
            user_id:user.user_id
        }).then(response => response.data).then((d) => {
          setUrl(d);
          window.location.replace(d.Signers[0].SignUrl); // Move the redirect code here
        });
    
    
        setIsLoading(false);
  
    }
    catch(err){
        setWait(false);
        return {success:0, message:'Server Error!'};
    }
  }

 

  useEffect(() => {
    if (signerStatus === 'signed' || signerStatus === 'open') {
    const fetchData = async () => {
    try{
        const {data} = await Axios.post('investment/investment_status.php',{
        investment_id:signerId,
        transaction_id:transactionId,
        transaction_status:signerStatus,
        user_id:user.user_id
        }).then(response => response.data).then((d) => setPayment(d));
          
        }
        
        catch(err){
            
            return {success:0, message:'Server Error!'};
        }
    };
fetchData();
    
    }
}, []);

 /* Create order */

 const handleCreatePayment = async (e) => {
    e.preventDefault();
    setWait(true);
    const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      e.preventDefault();
      setValidated(true);
  
    if(!Object.values(formData).every(val => val.trim() !== '')){
        
        return;
    }
  
    const data = await createPayment(formData);
    if(data.success){
        e.target.reset();
        
        
    }
    else if(!data.success && data.message){
        
    }
    
  }

 
  const [endChecked, setEndChecked] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const handleCheckboxChange = () => {
    setEndChecked(!endChecked);
    if (!endChecked) {
        setIsValid(false);
    } else {
        setIsValid(true);
    }
  };
 
  
  const createPayment = async () => {

    
    try{
        setIsLoading(true);
        const loginToken = localStorage.getItem('loginToken');
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
        const {data} = await Axios.post(payment.recurring === 1 ? 'mollie/create_first_payment.php' : 'mollie/create_payment.php',{
          user_id: user.user_id,
          order_id: payment.order.order_id,
          order_id_ext: payment.order.order_id_ext,
          order_amount: payment.order.order_amount,
          investment_id: payment.order.investment_id,
          user_email: user.email,
          user_firstname: user.first_name,
          user_lastname: user.last_name

        }).then(response => response.data).then((d) => {
          setCheckout(d);
          window.location.replace(d.checkoutUrl); // Move the redirect code here
        });
      
    
        setIsLoading(false);
        
       // setErrMsg(false);

       
        
  
    }
    catch(err){
        setWait(false);
        return {success:0, message:'Server Error!'};
    }
  
    
  }

if (isLoading) {
    return <Redirecting />;
  }

    return (

        

        <div id="dashboard">

            <Topbar/>   
            <Sidebar/> 
            <div className="board">
            {signerStatus === 'signed' || signerStatus === 'open'  ?
            <div className="card card-large">
                 <div className="card-body">

                
                 
                 <h3 className="text-left">We hebben uw inschrijving ontvangen</h3>
                 
              {payment && payment != null && payment.order && payment.recurring === 0 && (
                <>
                <p>Tot slot verzoeken wij u het ingeschreven bedrag over te maken vanuit een eigen bankrekening (niet van een derde) in een EU-lidstaat, op:</p>
              <div className="spacer s mt-3"></div>
              <div className="card full fw mt-3">
                <div className="card-body">
                <p className="small muted mb-1">Bedrag:</p> <p>€<NumericFormat 
             value={payment.order.order_amount}
              decimalScale={2} 
              min={1000}
              decimalSeparator={text.decimal_seperator} 
              allowedDecimalSeparators={text.decimal_seperator} 
              allowLeadingZeros 
              thousandSeparator={text.thousand_seperator} 
              displayType="text"
              /></p>
                <p className="small muted mb-1 mt-2">Bankrekeningnummer:</p> <p>NL25 RABO 0375 545 387</p>
                <p className="small muted mb-1 mt-2">Ten name van:</p> <p>Stichting Alpha High Performance Fund - Capler PE</p>
                <p className="small muted mb-1 mt-2">Onder vermelding van uw ordernummer:</p> <p>{payment.order.order_id_ext}</p>
                </div>
              </div>
              <Alert key='warning' variant='warning' className="fw mt-4">
                Let op! Om uw belegging te kunnen betalen moet uw daglimiet toereikend zijn. Hoe u deze kunt wijzigen, en hoe lang het duurt voordat deze is doorgevoerd, scheelt per bank. &nbsp;
                <Alert.Link target="_blank" href="https://capler.nl/2022/05/31/daglimiet/">Lees hier meer</Alert.Link>
              </Alert>
              {signerStatus === 'open' && (
                 <Alert key='warning' variant='warning' className="fw mt-4">
                 Er is helaas iets misgegaan met de ondertekening. U kunt uw belegging verder afronden en de betaling in orde maken. Binnenkort zullen wij contact met u opnemen om de ondertekening van uw inschrijving alsnog uit te voeren.
                
               </Alert>
              )}
              {payment.pension === 1 && (
              <Alert key='warning' variant='warning' className="fw mt-4">
                Let op! Indien u een bestaande lijfrente van een andere financiële instelling wilt overzetten, kunt u <Alert.Link target="_blank" href="https://capler.nl/files/documents.php?doc=annuitytransfer">hier</Alert.Link> het formulier downloaden. In plaats van direct te betalen, kiest u voor "later betalen".
               
              </Alert>
              )}
              {payment.order.order_amount > 50000 && (
                 <Alert key='warning' variant='warning' className="fw mt-4">
                 Let op! Indien u meer dan €50.000 belegt, kunt u uitsluitend per handmatige overboeking betalen. De gegevens voor handmatige overboeking kunt u te allen tijde terug vinden bij "Mijn beleggingen".
                
               </Alert>
              )}

              
              <div className="card-btn d-flex">
               
                
                {payment.order.order_amount > 50000 ?
                <Form className="full ml-auto mt-3" onSubmit={handleClose}>
                            
                <button
                className="btn mt-3 ml-auto float-end btn-primary"
                type="submit"

                >
                Investering afronden en afsluiten
                </button>
                </Form>
                :
                <>
                <Form className="full ml-auto mt-3" onSubmit={handleClose}>
                            
                <button
                className="btn mt-3 ml-auto float-end btn-link" 
                type="submit"

                >
                Later betalen
                </button>
                </Form>
                <Form className="full ml-auto mt-3" onSubmit={handleCreatePayment}>
                <button
                className="btn mt-3 ml-auto float-end btn-primary" 
                type="submit"

                >
                Direct online betalen
                </button>
                 </Form>
                 </>
                }
                          
                           

                </div>
                </>

              )}

{payment && payment != null && payment.order && payment.recurring === 1 && (
                <>
                <p>Selecteer de door u gewenste betaalmethode voor uw maandelijkse belegging.</p>
                

                <Form className="large mt-0">

           

<h5>Hoe wilt u de periodieke betaling uitvoeren?</h5>
{/*
<Form.Text className="text-muted">
Lees <a target="_blank" href="https://capler.nl/files/documents.php?doc=annuity">hier</a> de aanvullende voorwaarden lijfrente.
</Form.Text>
*/}

{['radio'].map((type) => (
<div key={`default-${type}`} className="mb-3 mt-3">
  <Form.Check // prettier-ignore
    type={type}
    id="regular"
    name="method"
    label="Ik geef een machtiging voor automatische incasso (tot max €1.000 per maand)"
    value="regular"
    checked={!selected}
    onChange={handleChange}
    defaultChecked
    disabled={payment.order.order_amount > 1000}
  />

  <Form.Check
    type={type}
    label="Ik wil zelf een periodieke overboeking instellen via mijn bank, internetbankieren of app."
    id="pension"
    value="pension"
    name="method"
    checked={selected || payment.order.order_amount > 1000}
    onChange={handleChange} 
  />
</div>
))}

</Form>

              {selected  || payment.order.order_amount > 1000 ?

                <>
              <div className="card full fw mt-3">
                <div className="card-body">
                <p className="small muted mb-1">Bedrag:</p> <p>€<NumericFormat 
             value={payment.order.order_amount}
              decimalScale={2} 
              min={1000}
              decimalSeparator={text.decimal_seperator} 
              allowedDecimalSeparators={text.decimal_seperator} 
              allowLeadingZeros 
              thousandSeparator={text.thousand_seperator} 
              displayType="text"
              /></p>
                <p className="small muted mb-1 mt-2">Bankrekeningnummer:</p> <p>NL25 RABO 0375 545 387</p>
                <p className="small muted mb-1 mt-2">Ten name van:</p> <p>Stichting Alpha High Performance Fund - Capler PE</p>
                <p className="small muted mb-1 mt-2">Onder vermelding van uw contractnummer:</p> <p>REC-{payment.reference}</p>
                </div>
              </div>
             
              <Form className="large mt-0">
              <h5 className="mt-5">Verklaring periodieke overboeking</h5>
              <div className="mb-3 mt-3">
              <Form.Check
              type="checkbox"
              name="end_investment"
              label="Ik verklaar een automatische periodieke overboeking via mijn bank, internetbankieren of app ingesteld te hebben."
              checked={endChecked}
              onChange={handleCheckboxChange}
              className=""
              />
              </div>
              </Form>

              {signerStatus === 'open' && (
                 <Alert key='warning' variant='warning' className="fw mt-4">
                 Er is helaas iets misgegaan met de ondertekening. U kunt uw belegging verder afronden en de betaling in orde maken. Binnenkort zullen wij contact met u opnemen om de ondertekening van uw inschrijving alsnog uit te voeren.
                
               </Alert>
              )}

<div className="card-btn d-flex">
              <Form className="full ml-auto mt-2" onSubmit={handleClose}>
                            
                            <button
                            disabled={isValid}
                            className="btn mt-0 ml-auto float-end btn-primary" 
                            type="submit"
            
                            >
                            Inschrijving afronden en afsluiten
                            </button>
                            </Form>
              

                </div>


              </>
              :

              <>
              {signerStatus === 'open' && (
                 <Alert key='warning' variant='warning' className="fw mt-4">
                 Er is helaas iets misgegaan met de ondertekening. U kunt uw belegging verder afronden en de betaling in orde maken. Binnenkort zullen wij contact met u opnemen om de ondertekening van uw inschrijving alsnog uit te voeren.
                
               </Alert>
              )}
              
              <Form className="full ml-auto mt-3" onSubmit={handleCreatePayment}>
                            
                            <button
                            className="btn mt-3 ml-auto float-end btn-primary" 
                            type="submit"
            
                            >
                           Direct online betalen en machtigen
                            </button>
                </Form>
                </>
              
              }


             
          
                
             
                </>

              )}
             
              </div>
                </div>
                :
                <div className="card card-large">
                 <div className="card-body">
                 
                 <h3 className="text-left">Uw ondertekening was afgebroken</h3>
                 <p>Wilt u de ondertekening hervatten of uw volledige inschrijving annuleren? Als u annuleert, gaan de bijbehorende gegevens verloren.</p>
              <div className="spacer s mt-3"></div>
              <div className="card-btn d-flex">
              <Form className="full ml-auto" onSubmit={handleCancel}>
                            
                            <button
                            className="btn btn-link" 
                            type="submit"
            
                            >
                            Annuleren
                            </button>
                            </Form>
              <Form className="full ml-auto" onSubmit={handleSign}>
                            
                            <button
                            className="btn ml-auto float-end btn-primary" 
                            type="submit"
            
                            >
                            Ondertekening hervatten
                            </button>
                            </Form>
                            </div>
             </div>
             </div>
            }

            </div>
            
        </div>
        

    )
}

export default SignatureResponse;
