import React  from 'react';
import {useContext, useEffect} from 'react'
import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import {UserContext} from './context/UserContext';
import Login from './components/Login';
import Register from './components/Register';
import Verification from './components/Verification';
import Forgot from './components/Forgot';
import Home from './pages/Home';
import Invest from './pages/Invest';
import Funds from './pages/Funds';
import Fund from './pages/fund/Fund';
import Portfolio from './pages/Portfolio';
import './index.css';
import Inbox from './pages/Inbox';
import Profiles from './pages/Profiles';
import FundDocuments from './pages/folders/FundDocuments';
import Documents from './pages/Documents';
import Contracts from './pages/folders/Contracts';
import Mutations from './pages/folders/Mutations';
import Updates from './pages/folders/Updates';
import Identification from './pages/Identification';
import IdentificationManual from './pages/IdentificationManual';
import OnfidoWrapper from './pages/identification/OnfidoWrapper';
import Loading from './pages/identification/Loading';
import Result from './pages/identification/Result';
import Account from './pages/settings/Account';
import SetProfiles from './pages/settings/Profiles';
import SetRecurring from './pages/settings/Recurring';
import Filesharing from './pages/settings/Filesharing';
import SignatureResponse from './pages/invest/SignatureResponse';
import PaymentResponse from './pages/invest/PaymentResponse';
import Test from './components/dashboard/Test';
import Subfund from './pages/fund/Subfund';


function App() {

  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.removeItem('setProgress');
      localStorage.removeItem('profileProgress');
    };

    window.addEventListener('beforeunload', clearLocalStorage);

    return () => {
      window.removeEventListener('beforeunload', clearLocalStorage);
    };
}, []);
  
  const queryParams = new URLSearchParams(window.location.search);
  const idStatus = queryParams.get('onf_redirect');
  const signerStatus = queryParams.get('sh_signerstatus');
  const transactionId = queryParams.get('sh_transactionid');
  const signerId = queryParams.get('sh_signerid');
  const transactionIdExt = queryParams.get('transaction_id');
  const {user, profile, text} = useContext(UserContext); 

  if (signerStatus === 'signed') {
    //localStorage.setItem('setProgress', 'transaction');
  }
  

  if (user === undefined) {
    return null; // or loading indicator/spinner/etc
  }

 
  
 
  return (
    
        
        <BrowserRouter>
        
          <Routes>
            { user && user.role === 'member' && (
              <>
              <Route path="/" element={<Home/>} /> 
              {profile != 'none' ?
              <Route path="/invest" element={<Invest/>} /> 
              : user.id_progress === 'COMPLETED' ?
              <Route path="/invest" element={<Profiles/>} />
              : 
              <Route path="/invest" element={<IdentificationManual/>} />
              }

              <Route path="/profile/identification" element={<IdentificationManual/>} /> 

              <Route path="/identification" element={<IdentificationManual/>} /> 
              <Route path="/identification/*" element={<IdentificationManual/>} /> 
              <Route path="/identification/manual" element={<IdentificationManual/>} />
              <Route path="/identification/onfido" element={<OnfidoWrapper/>} /> 
              <Route path="/identification/loading" element={<Loading/>} /> 
              <Route path="/identification/result" element={<Result/>} /> 
              {user.has_investments != 'yes' ?
              <Route path="/documents" element={<FundDocuments/>} /> 
              : 
              <Route path="/documents" element={<Documents/>} /> 
              }
              <Route path="/documents/fund" element={<FundDocuments/>} /> 
              <Route path="/documents/contracts" element={<Contracts/>} />
              <Route path="/documents/mutations" element={<Mutations/>} /> 
              <Route path="/documents/updates" element={<Updates/>} /> 
              <Route path="/funds" element={<Funds/>} /> 
              <Route path="/funds/:public_id" element={<Fund/>} /> 
              <Route path="/funds/:public_id/:public_id" element={<Subfund/>} />
              
             
              <Route path="/portfolio" element={<Portfolio/>} /> 
             
              <Route path="/settings/*" element={<Navigate to='/settings/account' />}/>
              <Route path="/settings/account" element={<Account/>}/>
              <Route path="/settings/profiles" element={<SetProfiles/>}/>
              <Route path="/settings/recurring" element={<SetRecurring/>}/>
              <Route path="/settings/filesharing" element={<Filesharing/>}/>
              <Route path="/inbox" element={<Inbox/>} /> 
              <Route path="/inbox/*" element={<Inbox/>} /> 
              <Route path="/profiles" element={<Profiles/>} /> 
              <Route path="/profiles/*" element={<Profiles/>} />
              <Route path="/sign" element={<SignatureResponse />} /> 
              <Route path="/test" element={<Test />} /> 

              {signerStatus === 'signed' && <Route path="*" element={<SignatureResponse />} />}
              {transactionIdExt && <Route path="*" element={<PaymentResponse />} />}
              </>
            )}
            {!user && (
              <>
              <Route path="/login" element={<Login/>} />
              <Route path="/signup" element={<Register/>} />
              <Route path="/forgot" element={<Forgot/>} />
              <Route path="/verification" element={<Navigate to={user ? '/':'/login'} />} />
              <Route path="/verification/*" element={<Verification/>} />
      
              </>
            )}
            
            {idStatus && <Route path="*" element={<Navigate to={user ? 'identification/result':'identification/result'} /> }/> }
            {signerStatus && <Route path="*" element={<Navigate to={user ? 'sign/?sh_signerstatus='+signerStatus+'&sh_signerid='+signerId+'&sh_transactionid='+transactionId:'sign/?sh_signerstatus='+signerStatus+'&sh_signerid='+signerId+'&sh_transactionid='+transactionId} />} />}
            {transactionIdExt && <Route path="*" element={<Navigate to={user ? 'payment/?transaction_id='+transactionIdExt:'payment/?transaction_id='+transactionIdExt} />} />}
            <Route path="*" element={<Navigate to={user ? '/':'/login'} />} />
            
            
            

          </Routes>
          
        </BrowserRouter>
    
  );
}

export default App;