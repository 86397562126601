import React from "react";
import {useContext} from 'react'
import {UserContext} from '../../context/UserContext'
import { Link } from 'react-router-dom';
import {Button} from 'react-bootstrap';

const CTA = ({ filteredFund, heading }) => {

    const {text, user} = useContext(UserContext);

    return (
        <>
        <div className="card card-full table-card cta-hidden">
            <div className="card-body">
                <h4 className="mb-4">{heading}</h4>
                <p className="sub">Een aantal bedrijven uit de portefeuille van {filteredFund.name}.</p>
                <div className="companies dummy">
                    <div className="card" >
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card" >
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card" >
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card" >
                        <div className="card-body">
                        </div>
                    </div>
                 
              
              </div>
            </div>
        </div>

<div className="card card-full table-card cta">
<div className="card-body d-flex aic">
    <div class="left">
    <h4 className="mb-4">Exclusief voor beleggers</h4>
    <p className="sub mb-5">Bepaalde gedetailleerde informatie is exclusief beschikbaar voor onze huidige beleggers.</p>
    {        
    user.has_investments === 'yes' && user.participated === 'no' ?
    <Link className="card-btn mt-3" ><Button disabled variant="primary">Uw inschrijving is in behandeling</Button></Link>       
    : !user.id_first_name ?
    <Link className="card-btn mt-3" to='/invest'><Button variant="primary">Start uw eerste belegging</Button></Link>
    :
    <Link className="card-btn mt-3" to='/invest'><Button variant="primary">Vervolg uw eerste belegging</Button></Link>
    }
</div>
<div class="right hide-medium">
    <svg id="cta-graph" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.73 141.73">
        <rect class="cls-cta-graph" x="37.22" y="37.6" width="29.12" height="29.12" rx="8.43" ry="8.43" transform="translate(-21.72 51.89) rotate(-45)"/>
        <rect class="cls-cta-graph" x="78.4" y="37.6" width="29.12" height="29.12" rx="8.43" ry="8.43" transform="translate(-9.65 81.01) rotate(-45)"/>
        <rect class="cls-cta-graph" x="37.22" y="78.78" width="29.12" height="29.12" rx="8.43" ry="8.43" transform="translate(-50.84 63.95) rotate(-45)"/>
        <rect class="cls-cta-graph" x="78.4" y="78.78" width="29.12" height="29.12" rx="8.43" ry="8.43" transform="translate(-38.78 93.08) rotate(-45)"/>
    </svg>
</div>

</div>
</div>
</>
    );
};

export default CTA;