import React  from 'react';
import {useContext, useState} from 'react'
import {UserContext} from '../../context/UserContext'
import { NavLink} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const Sidebar = () => {

    const {profile, text} = useContext(UserContext);

    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


    let activeStyle = {
        background: "black",
        color: "white"
      };

    return (
        <>
        <nav className="nav sidebar flex-column">
        
                <NavLink    
                    className='nav-link show-phone' 
                    to='/invest' 
                    style={({ isActive }) => isActive ? activeStyle : undefined}
                >
                    <i class="bi bi-plus-square"></i> Start een belegging
                </NavLink>
        
                <NavLink    
                    className='nav-link' 
                    to='/' 
                    style={({ isActive }) => isActive ? activeStyle : undefined}
                >
                    <i className="bi bi-speedometer2"></i> {text.dashboard}
                </NavLink>
                <NavLink    
                    className='nav-link' 
                    to='/portfolio' 
                    style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                    }>
                    <i className="bi bi-wallet"></i> {text.my_investments}
                </NavLink>
                 
                <NavLink    
                    className='nav-link' 
                    to='/funds/capler-private-equity' 
                    style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                    }>
                    <i class="bi bi-pie-chart"></i> {text.the_fund}
                </NavLink>
                 
                <NavLink    
                    className='nav-link' 
                    to='/documents' 
                    style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                    }>
                    <i className="bi bi-file-earmark-text"></i> {text.documents}
                </NavLink>
                {/*}
                <NavLink    
                    className='nav-link' 
                    to='/identification' 
                    style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                    }>
                    <i className="bi bi-file-earmark-text"></i>ID
                </NavLink>
                */}
              
         

        <NavLink target="_blank" className="nav-link mt-auto extra hide-medium" to="https://capler.nl/support">
        <svg id="symbol" viewBox="0 0 55.2 39.7" >
            <g>
                <g>
                    <path className="st0" d="M54.5,31.1L37.6,1.9c-1.5-2.6-5.2-2.6-6.7,0L20.1,20.5l9.4,16.3c1,1.8,2.9,2.9,5,2.9h14.9
                            C53.9,39.7,56.7,34.9,54.5,31.1"/>
                </g>
                <g>
                    <path className="st0" d="M5.8,39.7L5.8,39.7c2.1,0,4-1.1,5-2.9l9.4-16.3l-3.3-5.7c-1.5-2.6-5.2-2.6-6.7,0L0.8,31.1
                            C-1.4,34.9,1.3,39.7,5.8,39.7"/>
                </g>
            </g>   
        </svg>
            {text.knowledge_base}<i className="float-end bi bi-arrow-down-right"></i></NavLink>
        <Link className="nav-link extra hide-medium" onClick={() => handleShow()}>Neem contact op <i className="float-end bi bi-question-circle"></i></Link>
      </nav>


      

    <Modal size="md" show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Vragen over Capler?</Modal.Title>
    </Modal.Header>
    
    <Modal.Body>
        
    <p className="mb-3">
Neem contact met ons op.</p>
      
      <p className="mb-0"><i class="bi bi-envelope mr-2"></i> info@capler.nl</p>
      <p className="mb-0"><i class="bi bi-telephone"></i> +31 (0)20 30 86 538</p>
        <p><i class="bi bi-whatsapp"></i> +31 (0)20 30 86 538</p>
      
    </Modal.Body>
   
    
    </Modal>
    </>


    )
}

export default Sidebar;


