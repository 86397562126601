import React from "react";
import {useState, useContext, useEffect} from 'react';
import {UserContext} from '../../context/UserContext';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';



const Partner = ({partner, setPartner, partnerRef, props}) => {

  const [validated, setValidated] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);

  const {user} = useContext(UserContext);

  const persBus = localStorage.getItem('persBus');
  const relation = localStorage.getItem('relation');

  const handleChange = (e) => {
    setPartner((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
   
  };

  

  const formOnChageHandler = (e) => {
    
   
   
  };

  

  const partnerSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    
    setValidated(true);
  };

    return (

      <>

      <h4>Persoonsgegevens partner</h4>

      {relation === 'partner' && (
        <p>Om gezamenlijk te beleggen, wordt u beiden verzocht zich te registreren bij Capler. Geef hieronder de gegevens van uw partner op.</p>
      )}
     
        
        <Form className="large" noValidate onChange={formOnChageHandler} validated={validated} onSubmit={partnerSubmit}>
       
        <Row className="mb-3">
        
        <Form.Group as={Col} md="5" controlId="validationCustom01">
            <Form.Label>Voornamen</Form.Label>
            
            <Form.Control
              name='partner_firstname'
              onChange={handleChange} 
              value={partner.partner_firstname}
              required
              type="text"
            />
            
          
          </Form.Group>

          <Form.Group as={Col} md="5" controlId="validationCustom01">
            <Form.Label>Achternaam</Form.Label>
            
            <Form.Control
              name='partner_lastname'
              onChange={handleChange} 
              value={partner.partner_lastname}
              required
              type="text"
            />
            
          
          </Form.Group>

          </Row>
          <Row className="mb-3">

        
          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Label>Geboortedatum</Form.Label>
            
            <Form.Control
              name='partner_date_of_birth'
              onChange={handleChange} 
              value={partner.partner_date_of_birth}
              required
              type="date"
            />
            
          
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Label>BSN</Form.Label>
            
            <Form.Control
              name='partner_bsn'
              onChange={handleChange} 
              value={partner.partner_bsn}
              required
              type="number"
            />
            
          
          </Form.Group>
          </Row>
          <Row className="mb-3">

          <Form.Group as={Col} md="8" controlId="validationCustom01">
            <Form.Label>E-mailadres</Form.Label>
            
            <Form.Control
              name='partner_email'
              onChange={handleChange} 
              value={partner.partner_email}
              required
              type="email"
            />
            {partner.partner_email == user.email && ( 
              <div className="validationWarning">
                Uw partner kan zich niet registreren met hetzelfde e-mailadres.
              </div>
            )}
          
          </Form.Group>
          </Row>

        <Button ref={partnerRef} className='btn-secondary' style={{ display: 'none' }} type="submit">Bevestig contactgegevens</Button>
        
      </Form>

      </>

    )
}

export default Partner;
