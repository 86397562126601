import React  from 'react';
import {useContext} from 'react'
import {UserContext} from '../../context/UserContext'
import ProfileWallets from './ProfileWallets';
import { NumericFormat } from 'react-number-format';
import { Link } from 'react-router-dom';

const Wallet = ({handleCreatePayment}) => {

    const {profile} = useContext(UserContext);

    return (
        
        <div className="board">
       

        {profile.map((participatedProfile) => ( 
         <>
         {participatedProfile.pers_bus === 'pers' && 
         ((participatedProfile.reg_ordered_amount > 0 || participatedProfile.reg_ordered_amount > 0 || participatedProfile.reg_accepted_amount > 0) && (
            <ProfileWallets 
            handleCreatePayment={handleCreatePayment} 
            key={participatedProfile.profile_id}   
            pension={'no'} 
            participatedProfile={participatedProfile}
            />
        ))}

        {participatedProfile.pers_bus === 'bus' && 
        ((participatedProfile.reg_ordered_amount > 0 || participatedProfile.reg_accepted_amount > 0) && (
            <ProfileWallets 
            handleCreatePayment={handleCreatePayment} 
            key={participatedProfile.profile_id} 
            pension={'no'} 
            participatedProfile={participatedProfile}
            />
        ))}
        </>
    ))}



    {profile && profile.map((participatedProfile) => ( 
        <>
            {participatedProfile.pension === 'yes' && (
            <ProfileWallets handleCreatePayment={handleCreatePayment} key={participatedProfile.profile_id} pension={'yes'} participatedProfile={participatedProfile}/>
            )}
            </>
        ))}

  

    

    </div>

    )


}

export default Wallet;