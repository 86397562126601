import React from "react";
import {useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/UserContext'
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios'

export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
  });


const Persbus = (props) => {

    const navigate = useNavigate();

    const {user, loggedInCheck} = useContext(UserContext);

    const persBus = localStorage.getItem('persBus');

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [validated, setValidated] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [wait, setWait] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});


/* Create profile */

const onChangeForm = (e) => {
    setFormData({
        ...formData,
        [e.target.name]:e.target.value
    })
}

const handleCreateProfile = async (e) => {
      
    e.preventDefault();
    const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      e.preventDefault();
      setValidated(true);
      
  
    if(!Object.values(formData).every(val => val.trim() !== '')){
        
        return;
    }
  
    const data = await createProfile(formData);
  
        e.target.reset();
    
  }
  
  const createProfile = async () => {
    
    setLoading(true);
    setWait(true);
    try{
     
        const loginToken = localStorage.getItem('loginToken');
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
        const {data} = await Axios.post('relation/update.php',{
          user_id: user.user_id,
          rel_id_ext: formData.rel_id_ext

        });

        if(data.success){
          
          navigate('/invest');
          await loggedInCheck();
          
          setWait(false);
        }
        else if(!data.success && data.message){
            setSuccessMsg(false);
            setErrMsg(data.message);
        }
       
        
        
  
    }
    catch(err){
        setWait(false);
        return {success:0, message:'Server Error!'};
    }
  
    
  }

return (

    <>
     <div className="card-top">
    {user.has_profiles && user.has_profiles === 'yes' ?
    <h4>Maak een nieuw profiel aan</h4>
    :
    <h4>Maak uw eerste profiel aan</h4>
    }
    <div className="gap-s">
    <Link onClick={handleShow} className="btn hide-phone">+ Gebruik koppelcode</Link>
    </div>
    </div>
    <p>Selecteer het type beleggersprofiel waarmee u wilt beleggen. Let op! Als u pensioen wilt beleggen, kan dat uitsluitend met een <u>persoonlijk</u> profiel.</p>

    <div className="profiles mw-1100">

    <label>
        <input 
        type="radio" 
        name="profile" 
        value='pers' 
        className="card-input-element" 
        onChange={(e)=>{props.onPersbusChange(e.target.value)}} 
        defaultChecked={'pers' === persBus && user.has_personal_profile !== 'yes'}
        disabled={user.has_personal_profile === 'yes'}
        />
        <div className="card">
            <div className="card-body">
                <h5>Privé (persoonlijk)</h5>
                {user.has_personal_profile === 'yes' ?
                <p>U heeft al een privé profiel</p>
                :
                <p>Als natuurlijk persoon</p>
                }
                <div className="badge-container d-flex mt-3">
                <span class="badge text-bg-light mw-fc"><i class="bi bi-check-all"></i> Regulier</span>
                <span class="badge text-bg-light mw-fc"><i class="bi bi-check-all"></i> Pensioen</span>
                </div>
                <i className="bi bi-check-circle-fill"></i>
                <i className="bi bi-circle-fill"></i>
            </div>
        </div>
     </label>

     <label>
        <input 
        type="radio" 
        name="profile" 
        value='bus' 
        className="card-input-element" 
        onChange={(e)=>{props.onPersbusChange(e.target.value)}} 
        defaultChecked={'bus' === persBus}
        />
        <div className="card">
            <div className="card-body">
                <h5>Zakelijk</h5>
                <p>Vanuit een bedrijf</p>
                <div className="badge-container d-flex mt-3">
                <span class="badge text-bg-light mw-fc"><i class="bi bi-check-all"></i> Regulier</span>
                </div>
                <i className="bi bi-check-circle-fill"></i>
                <i className="bi bi-circle-fill"></i>
            </div>
        </div>
     </label>


    <label>
        <input 
        type="radio" 
        name="profile" 
        value='perspart' 
        className="card-input-element" 
        onChange={(e)=>{props.onPersbusChange(e.target.value)}} 
        
        />
        <div className="card">
            <div className="card-body">
                <h5>Privé (gezamenlijk)</h5>
                <p>Met een partner</p>
                <div className="badge-container d-flex mt-3">
                <span class="badge text-bg-light mw-fc"><i class="bi bi-check-all"></i> Regulier</span>
                </div>
                <i className="bi bi-check-circle-fill"></i>
                <i className="bi bi-circle-fill"></i>
            </div>
        </div>
     </label>

     <label>
        <input 
        type="radio" 
        name="profile" 
        value='perschild' 
        className="card-input-element" 
        onChange={(e)=>{props.onPersbusChange(e.target.value)}} 

        />
        <div className="card">
            <div className="card-body">
                <h5>Privé (minderjarige)</h5>
                <p>Voor een kind of kleinkind</p>
                <div className="badge-container d-flex mt-3">
                  <span class="badge text-bg-light mw-fc"><i class="bi bi-check-all"></i> Regulier</span>
                 
                </div>
                <i className="bi bi-check-circle-fill"></i>
                <i className="bi bi-circle-fill"></i>
            </div>
        </div>
     </label>

     </div>

     <Modal show={show} onHide={handleClose}>
     <Form onSubmit={handleCreateProfile} onChange={onChangeForm}>
        <Modal.Header closeButton>
          <Modal.Title>Gezamenlijk beleggen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
            Voer de koppelcode in om een gezamenlijk profiel toe te voegen aan uw account.
            </p>

        
            <Form.Group className="mt-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                placeholder="xxxx-xxxx"
                maxLength="9"
                name="rel_id_ext"
                autoFocus
              />
            </Form.Group>
            
         

        </Modal.Body>
        <Modal.Footer>
        <Button variant="link" onClick={handleClose}>
            Sluiten
          </Button>
          <Button type="submit" variant="primary">
            Opslaan
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>

    </>

    

)
}

export default Persbus;