import React from "react";


const Companies = ({ filteredFund, heading }) => {



    const allCompanies = filteredFund && filteredFund.public_id === 'capler-private-equity' ? filteredFund.subfunds.flatMap(subfund => subfund.companies) : [];
const sortedCompanies = [...allCompanies].sort((a, b) => a.name.localeCompare(b.name));
    

    return (
        <div className="card card-full table-card">
            <div className="card-body">
                <h4 className="mb-4">{heading}</h4>
                <p className="sub">Een aantal bedrijven uit de portefeuille van {filteredFund.name}.</p>
                <div className="companies">

                    {filteredFund.public_id === 'capler-private-equity' ?
                    <>
                    {sortedCompanies.map(filteredCompany => (
                        <div className="card" key={filteredCompany.public_id}>
                            <div className="card-body">
                                <div className="tt_icon extra-large" style={{backgroundImage: `url("https://admin.capler.nl/public_assets/${filteredCompany.logo}")`}}>
                                </div>
                                <div className="bottom">
                                    <h6 className="text-center">{filteredCompany.name}</h6>
                                    <p className="sub text-center">{filteredCompany.sector}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                    </>
                    :
                    <>
                    {filteredFund.companies.map(filteredCompany => (
                        <div className="card" key={filteredCompany.public_id}>
                            <div className="card-body">
                           
                                <div className="tt_icon extra-large" style={{backgroundImage: `url("https://admin.capler.nl/public_assets/${filteredCompany.logo}")`}}>
                                </div>
                                <div className="bottom">
                                    <h6 className="text-center">{filteredCompany.name}</h6>
                                    <p className="sub text-center">{filteredCompany.sector}</p>
                                </div>
                            
            
                            </div>
                        </div>
                    ))}
                    </>
                }
              
              </div>
            </div>
        </div>
    );
};

export default Companies;