import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { NumericFormat } from 'react-number-format';

const Investment = (props) => {
  const { profile, text } = useContext(UserContext);

  const selectedProfile = localStorage.getItem('selectedProfile');
  const selectedMethod = localStorage.getItem('selectedMethod');
  const setAmount = parseFloat(localStorage.getItem('setAmount')) || 0;
  const minSetAmount = parseFloat(localStorage.getItem('minSetAmount')) || 0;
  const selectedRecurring = localStorage.getItem('selectedRecurring');

  const [selected, setSelected] = useState(selectedMethod);
  const [paymentMethod, setPaymentMethod] = useState(localStorage.getItem('selectedPaymentMethod'));
  const [recurring, setRecurring] = useState(selectedRecurring);
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (event) => {
    localStorage.setItem('selectedMethod', event.target.value);
    setSelected(event.target.value);
  };

  const handlePaymentMethod = (event) => {
    localStorage.setItem('selectedPaymentMethod', event.target.value);
    setPaymentMethod(event.target.value);
    if (event.target.value === 'transfer') {
      localStorage.setItem('selectedRecurring', 'no');
      setRecurring('no');
    }
  };

  const handleRecurring = (event) => {
    localStorage.setItem('selectedRecurring', event.target.value);
    setRecurring(event.target.value);
    if (event.target.value === 'yes') {
      localStorage.setItem('selectedPaymentMethod', 'transaction');
      localStorage.setItem('setAmount', 0);
      localStorage.setItem('brutAmount', 0);
      setPaymentMethod('transaction');
    } else {
      localStorage.setItem('setAmount', 0);
      localStorage.setItem('brutAmount', 0);
    }
    props.onRecurringChange(event.target.value);
  };

  const brutAmount = isChecked ? (setAmount / 96.05) * 100 : setAmount;
  const netAmount = isChecked ? setAmount : setAmount * 0.9605;
  const costAmount = brutAmount - netAmount;

  const annualReturnRate = 0.093; // 9.3%
  const years = 10;
  const futureValue = brutAmount * Math.pow(1 + annualReturnRate, years);

  const months = 10 * 12;
  const invested = months * brutAmount;

  let recValue = 0;
  let monthlyReturnRate = Math.pow(1 + annualReturnRate, 1 / 12) - 1;

  for (let i = 0; i < months; i++) {
    recValue = (recValue + brutAmount) * (1 + monthlyReturnRate);
  }

  useEffect(() => {
    const updatedBrutAmount = isChecked ? (setAmount / 96.05) * 100 : setAmount;
    const formattedBrutAmount = parseFloat(updatedBrutAmount).toFixed(2);
    localStorage.setItem('brutAmount', formattedBrutAmount);
    setRecurring(selectedRecurring);
  }, [isChecked, setAmount, selectedRecurring]);

    return (
        
<>
                <h4 className="text-left">Bepaal uw belegging</h4>
              
                

                <div>
        {profile.filter(thisProfile => thisProfile.profile_id == selectedProfile).map((filteredProfile) => (
   <>
   <p>Hier stelt u vast met welk bedrag u gaat participeren en op welke manier.</p>

   <Form className="large">

   {filteredProfile.pers_bus === 'pers' && filteredProfile.relation === 'none' && (

    <>
    <h5>Wilt u via een lijfrente voor uw pensioen beleggen?</h5>

    <Form.Text className="text-muted">
    Lees <a target="_blank" href="https://capler.nl/files/documents.php?doc=annuity">hier</a> de aanvullende voorwaarden lijfrente.
    </Form.Text>


    {['radio'].map((type) => (
    <div key={`default-${type}`} className="mb-3 mt-3">
      <Form.Check // prettier-ignore
        type={type}
        id="regular"
        name="method"
        label="Nee"
        value="regular"
        checked={selected === 'regular'}
        onChange={handleChange}
        defaultChecked
      />

      <Form.Check
        type={type}
        label="Ja"
        id="pension"
        value="pension"
        name="method"
        checked={selected === 'pension'}
        onChange={handleChange} 
      />
    </div>
    ))}

    </>
    )}

  {selectedMethod === 'pension' && (
    <>
     <h5>Doet u een nieuwe pensioenbelegging of wilt u een bestaande <br className="hide-phone"></br>lijfrente van een andere financiële instelling overzetten?</h5>
     {/*}
     <Form.Text className="text-muted">
     Download <a target="_blank" href="https://capler.nl/files/documents.php?doc=annuitytransfer">hier</a> het formulier voor waardeoverdracht lijfrente.
     </Form.Text>
  */}
 
     {['radio'].map((type) => (
     <div key={`default-${type}`} className="mb-3 mt-3">
       <Form.Check // prettier-ignore
         type={type}
         id="transaction"
         name="paymentmethod"
         label="Nieuwe pensioenbelegging"
         value="transaction"
         checked={paymentMethod === 'transaction'}
         onChange={handlePaymentMethod}
         defaultChecked
       />
 
       <Form.Check
         type={type}
         label="Bestaande lijfrente overzetten"
         id="transfer"
         value="transfer"
         name="paymentmethod"
         checked={paymentMethod === 'transfer'}
         onChange={handlePaymentMethod} 
       />
     </div>
     ))}
     </>
  )}

{(filteredProfile.profile_ordered_amount > 0) || (filteredProfile.profile_accepted_amount > 0) ?
<>

<h5>Eenmalig of maandelijks?</h5>

 <Form.Text className="text-muted">
Leer hier meer over periodiek beleggen.
</Form.Text> 


{['radio'].map((type) => (
<div key={`recurring-${type}`} className="mb-3 mt-3">
  <Form.Check // prettier-ignore
    type={type}
    id="no"
    name="recurring"
    label="Eenmalig"
    value="no"
    checked={selectedRecurring === 'no'}
    onChange={handleRecurring}
    defaultChecked
  />

  <Form.Check
    type={type}
    label="Maandelijks"
    id="yes"
    value="yes"
    name="recurring"
    checked={selectedRecurring === 'yes'}
    onChange={handleRecurring} 
  />
</div>
))}

</>
:
<></>
}



{!recurring || recurring === 'no' ?
        <>

      <Form.Group className="mb-3" controlId="Amount">
        
        {(filteredProfile.profile_ordered_amount > 0) || (filteredProfile.profile_accepted_amount > 0) ?
        <>
        <h5>Eenmalige {isChecked ? 'netto' : 'bruto'} belegging</h5>
        <Form.Text className="text-muted">
          U kunt een belegging toevoegen vanaf €1.000,-
        </Form.Text>
        <span className="input-symbol-euro">
        <NumericFormat 
              onChange={(e)=>{props.onAmountChange(e.target.value, 1000.00)}}
              name='qn_amount_year' 
              className="form-control mt-3"  
              decimalScale={2} 
              min={1000}
              decimalSeparator={text.decimal_seperator} 
              allowedDecimalSeparators={text.decimal_seperator} 
              allowLeadingZeros 
              required
              thousandSeparator={text.thousand_seperator} 
              valueAsNumber
              />
              </span>

        
        </>
        : 
        <>
        <h5>Eenmalige {isChecked ? 'netto' : 'bruto'} belegging</h5>
        <Form.Text className="text-muted">
          U kunt participeren vanaf €5.000. 
        </Form.Text>
       
        <span className="input-symbol-euro">
        <NumericFormat 
              onChange={(e)=>{props.onAmountChange(e.target.value, 5000.00)}}
              name='qn_amount_year' 
              className="form-control mt-3"  
              decimalScale={2} 
              min={5000}
              decimalSeparator={text.decimal_seperator} 
              allowedDecimalSeparators={text.decimal_seperator} 
              allowLeadingZeros 
              required
              thousandSeparator={text.thousand_seperator} 
              valueAsNumber
              />
              </span>
          
        
        </>
        }
        {setAmount != null && setAmount.length > 1 && setAmount.length < 5 && setAmount+0 < minSetAmount+0 && (
            <div className="validationWarning">
      Zorg dat u het minimum bedrag bereikt
      </div>
        )
      }
      </Form.Group>
     

      </>

      :

      <>

        {((filteredProfile.has_pen_recurring === 'yes' && selected === 'pension')
        || (filteredProfile.has_reg_recurring === 'yes' && selected === 'regular')) ?
         
      
        <Form.Group className="mb-3" controlId="Amount">
        <h5>Maandelijks beleggen</h5>
        <Form.Text className="text-muted">
          Met deze methode in dit profiel belegt u al maandelijks.
          {/*Het bedrag wijzigen kan in uw persoonlijke instellingen.*/}
        </Form.Text>
        
       
      </Form.Group>
      :

      <Form.Group className="mb-3" controlId="Amount">
        <h5>Maandelijks {isChecked ? 'netto' : 'bruto'} beleggen</h5>
        <Form.Text className="text-muted">
          Maandelijkse incasso is mogelijk vanaf €100,-
        </Form.Text>
        <span className="input-symbol-euro">
        <NumericFormat 
              onChange={(e)=>{props.onAmountChange(e.target.value, 100.00)}}
              name='qn_amount_year' 
              className="form-control mt-3"  
              decimalScale={2} 
              min={1000}
              decimalSeparator={text.decimal_seperator} 
              allowedDecimalSeparators={text.decimal_seperator} 
              allowLeadingZeros 
              required
              thousandSeparator={text.thousand_seperator} 
              valueAsNumber
              />
              </span>
        
       
      </Form.Group>

      
      }

    </>

    }

<div class="form-check form-switch">
      <p class="text-muted form-text" for="flexSwitchCheckDefault">Bruto</p>
  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}></input>
  <p class="text-muted form-text" for="flexSwitchCheckDefault">Netto</p>
</div>
    
    </Form>

    
    <div className="d-flex amounts mt-4">

    
    
    <div className="card preview-amount">
      <div className="card-body">
        <table>
         
          <tr>
            <td>Bruto belegging</td>
            {setAmount == null || (setAmount != null && setAmount.length < 3 && setAmount+0 < minSetAmount+0) ? 
              <td>-</td>
            :   
              <td>€<NumericFormat value={parseFloat(brutAmount).toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></td>
            }
          </tr>
        
          <tr>
          <td></td>
          <td></td>
          </tr>
          <tr>
          <td></td>
          <td></td>
          </tr>
          <tr>
            <td>Netto belegging</td>
            {!setAmount || (setAmount != null && setAmount.length < 3 && setAmount+0 < minSetAmount+0) ? 
              <td>-</td>
            :   
              <td>€<NumericFormat value={parseFloat(netAmount).toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></td>
            }
            
          </tr>
          {!recurring || recurring === 'no' ?
            <>
          <tr>
          <td></td>
          <td></td>
          </tr>
          <tr>
          <td></td>
          <td></td>
          </tr>
          <tr>
            <td>Waarde na 10 jaar bij 9,3%</td>
            {!setAmount || (setAmount != null && setAmount.length < 3 && setAmount+0 < minSetAmount+0) ? 
              <td>-</td>
            :   
              <td>€<NumericFormat value={parseFloat(futureValue).toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></td>
            }
            
          </tr>
          </>
          :
          <>
          <tr>
          <td></td>
          <td></td>
          </tr>
          <tr>
          <td></td>
          <td></td>
          </tr>
          <tr>
            <td>Belegd na 10 jaar</td>
            {!setAmount || (setAmount != null && setAmount.length < 3 && setAmount+0 < minSetAmount+0) ? 
              <td>-</td>
            :   
              <td>€<NumericFormat value={parseFloat(invested).toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></td>
            }
            
          </tr>
          <tr>
          <td></td>
          <td></td>
          </tr>
          <tr>
          <td></td>
          <td></td>
          </tr>
          <tr>
            <td>Waarde na 10 jaar bij 9,3%</td>
            {!setAmount || (setAmount != null && setAmount.length < 3 && setAmount+0 < minSetAmount+0) ? 
              <td>-</td>
            :   
              <td>€<NumericFormat value={parseFloat(recValue).toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></td>
            }
            
          </tr>
          </>

          
          }
          
        </table>
        
      </div>
    </div>

    
   
  

      </div>
      

    </>
  ))}
</div>


</>
        
    )
}

export default Investment;


