import React  from 'react';
import {useContext} from 'react'
import {UserContext} from '../../context/UserContext'
import Topbar from '../../components/dashboard/Topbar';
import Sidebar from '../../components/dashboard/Sidebar';
import Breadcrumbs from './Breadcrumbs';
import DocsHeader from './DocsHeader';
import MutationOverview from '../../documents/MutationOverview';



const Mutations = () => {

    const { documents, profile } = useContext(UserContext);
    const uniqueNavIdsPerProfile = [];

if (profile && profile.length > 0) {
  profile.forEach((profileItem) => {
    const uniqueNavIds = [];
    if (profileItem.investments && profileItem.investments.length > 0) {
      profileItem.investments.forEach((investment) => {
        if (investment.orders && investment.orders.length > 0) {
          investment.orders.forEach((order) => {
            if (order.order_status === "accepted") {
              const navId = order.nav_id;
              const navDate = order.nav_date;
              const profileId = profileItem.profile_id;
              const profileName = profileItem.profile_name;
              if (!uniqueNavIds.some((item) => item.navId === navId)) {
                uniqueNavIds.push({ navId, navDate, profileId, profileName });
              }
            }
          });
        }
      });
    }
    if (uniqueNavIds.length > 0) {
        uniqueNavIdsPerProfile.push(uniqueNavIds); 
      }
  });
}

return (
  <div id="dashboard">
    <Topbar />
    <Sidebar />

    <div className="board">
      <Breadcrumbs />

      <div className="card card-full">
        <div className="card-body">
          <DocsHeader />

          {uniqueNavIdsPerProfile.map((navItems) =>
            navItems.map((navItem) => (
                <MutationOverview
                key={navItem.navId}
                navId={navItem.navId}
                navDate={navItem.navDate}
                profileId={navItem.profileId}
                profileName={navItem.profileName}
                />
            ))
            )}
        </div>
      </div>
    </div>
  </div>
)
}

export default Mutations;