import React  from 'react';
import {useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import {UserContext} from '../context/UserContext';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Banner from './Banner';

const Forgot = () => {
    const {forgotUser, wait} = useContext(UserContext);
    const [errMsg, setErrMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [formData, setFormData] = useState({
        email:''
    });

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if(!Object.values(formData).every(val => val.trim() !== '')){
            setSuccessMsg(false);
            setErrMsg('Voer uw e-mailadres in om door te gaan.');
            return;
        }

        const data = await forgotUser(formData);
        if(data.success){
            e.target.reset();
            setSuccessMsg('We hebben u een e-mail gestuurd.');
            setErrMsg(false);
        }
        else if(!data.success && data.message){
            setSuccessMsg(false);
            setErrMsg(data.message);
        }
        
    }

    return (
        <div className="splitter d-flex">
        <Banner/>
        
        <div className="split form">
            

        <div className="auth">
            <div className="d-flex aic mb-2 gap-s">
                <Link to="/login"><i className="bi bi-chevron-left "></i></Link>
                <h3>Herstellen</h3>
            </div>
            <p className="form-disc">
            We zullen u instructies sturen om uw wachtwoord opnieuw in te stellen en u weer op weg te helpen.
            </p>
            <Form onSubmit={submitForm}>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1"><i className="bi bi-envelope"></i></InputGroup.Text> 
                    <Form.Control type="email" name="email" onChange={onChangeInput} placeholder="E-mail" id="email" value={formData.email} required />
                </InputGroup>
                {successMsg && <div className="success-msg">{successMsg}</div>}
                {errMsg && <div className="err-msg">{errMsg}</div>}
                <button className="btn btn-primary" type="submit" disabled={wait}>Stuur me een e-mail</button>
                
            </Form>
            <div className="bottom-link"><Link to="/login">Inloggen</Link></div>
        </div>
        </div>
        </div>
    )
}

export default Forgot;
