import React  from 'react';
import {useContext, useState} from 'react'
import Topbar from '../../components/dashboard/Topbar';
import Sidebar from '../../components/dashboard/Sidebar';
import {UserContext} from '../../context/UserContext'
import FundIntro from './FundIntro';
import FundTable from './FundTable';
import Companies from './Companies';
import CTA from './CTA';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';

 

const Subfund = (props) => {

    const {funds, text, user} = useContext(UserContext);  
const numDescending = [...funds].sort((a, b) => b.order_date - a.order_date); 
const [fund, setFunds] = useState([]);
const [subfund, setSubfunds] = useState([]);

const pathParts = window.location.pathname.split('/funds/');

const fundName = pathParts[1].split('/')[0];

const subFundName = window.location.pathname.split('/').pop();

function loadFund(e) {
    const item = e.target.value;
    setFunds([...funds]);
  }

  function loadSubFund(e) {
    const item_2 = e.target.value;
    setSubfunds([...funds.subfunds]);
  }


    return (

        <div id="dashboard">

            <Topbar/>   
            <Sidebar/> 

      
           
                
            {funds.filter(fund => fund.public_id.toString() === fundName).map(filteredFund => {


                if (filteredFund >= '0') return(
                    <div className="board" key={filteredFund.public_id}>

                    

                    {filteredFund.subfunds.filter(subfund => subfund.public_id === subFundName).map(filteredSubfund => {

                    if (filteredSubfund >= '0') return(

                        <>
                        <Breadcrumb className="breadcrumbs">
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/funds/" + filteredFund.public_id }}>{filteredFund.name}</Breadcrumb.Item>
                       
                        <Breadcrumb.Item active>{filteredSubfund.name}</Breadcrumb.Item>
                        </Breadcrumb>

                      


                        <div className="card card-full">
                        <div className="card-body">

                        <div class="card-top">
                            <div class="fund-heading">
                                <div class="tt_icon large" style={{backgroundImage: `url("https://admin.capler.nl/public_assets/${filteredSubfund.logo}")`}}>
                                </div>
                                <div class="left">
                                    <h3 class="text-left">{filteredSubfund.name}</h3>
                                    <p class="sub">In beheer van {filteredSubfund.manager}</p>
                                </div>
                            </div>
                            <Link target="_blank" class="btn hide-phone" to={filteredSubfund.website}>
                                Bezoek website <i class="bi right bi-arrow-up-right"></i>
                            </Link>
                        </div>
                    
                    
                         

                        </div>
                        </div>

                       

                        {filteredSubfund.fundmeta && filteredSubfund.fundmeta.filter(fundmetum => fundmetum.category.toString() === 'fund').slice(0, 1).map(subfilteredData => {
                        if (subfilteredData >= '0') return(
                        <FundTable filteredFund={filteredSubfund} category={subfilteredData.category} heading={"Fondsoverzicht"} />
                        ) 
                        })}

                        {filteredSubfund.fundmeta && filteredSubfund.fundmeta.filter(fundmetum => fundmetum.category.toString() === 'intro').slice(0, 1).map(subfilteredData => {
                        if (subfilteredData >= '0') return(
                        <FundIntro filteredFund={filteredSubfund} category={subfilteredData.category} />
                        ) 
                        })}

                        {filteredSubfund.fundmeta && filteredSubfund.fundmeta.filter(fundmetum => fundmetum.category.toString() === 'manager').slice(0, 1).map(subfilteredData => {
                        if (subfilteredData >= '0') return(
                        <FundTable filteredFund={filteredSubfund} category={subfilteredData.category} heading={"Beheerder"} />
                        ) 
                        })}
                        {user.has_investments === 'yes' ?
                        <>
                        {filteredSubfund.companies && filteredSubfund.companies.slice(0, 1).map(subfilteredData => {
                        if (subfilteredData >= '0') return(
                        <Companies filteredFund={filteredSubfund} heading={"Een greep uit de portefeuille"} />
                        ) 
                        })}
                        </>
                        :
                        <CTA filteredFund={filteredSubfund} heading={"Een greep uit de portefeuille"} />
                        }

                        </>
                       
                    ) 
                    })}

                    </div>

                ) 

                

                


            })}

</div>

    )
}

export default Subfund;


