import React  from 'react';
import {useState} from 'react'
import axios from 'axios'




const StaticPdf = (props) => {

    const [wait, setWait] = useState(false);

   

   
    const handleDownload = async () => {
        try {
            const response = await axios.post('https://admin.capler.nl/filehandler/get.php',
            { dir: props.document.dir }, { responseType: 'arraybuffer' });
            
           
            if (response.status === 200) {
                
                const fileBlob = new Blob([response.data], {
                    type: "application/pdf"
                });
                const fileUrl = URL.createObjectURL(fileBlob);
                window.open(fileUrl, '_blank');
            } else {
                console.error('Error downloading file:', response.statusText);
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };  


    return (

        
    
        <div className="file">
            <div className="d-flex aic mw-60">
                <h3 className="muted"><i className="bi bi-file-text"></i> </h3>
                <p>{props.document.title} {props.document.template_id === '1' && ( props.document.name )}</p>
                
            </div>   
         
            <p>{props.document.date}</p>
            {props.document.public === 1 ?
            <a target="_blank" download href={props.document.dir} className="btn">Download</a>
            :
            <button onClick={handleDownload} className="btn">Download</button>
            }
        </div>

        

    )
}

export default StaticPdf;