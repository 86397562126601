import React  from 'react';
import {useContext} from 'react'
import {UserContext} from '../../context/UserContext'
import Topbar from '../../components/dashboard/Topbar';
import Sidebar from '../../components/dashboard/Sidebar';
import Breadcrumbs from './Breadcrumbs';
import DocsHeader from './DocsHeader';
import MonthlyUpdate from '../../documents/MonthlyUpdate';



const Updates = () => {

    const { funds } = useContext(UserContext);
    const uniqueNavIdsPerProfile = [];



return (
  <div id="dashboard">
    <Topbar />
    <Sidebar />

    <div className="board">
      <Breadcrumbs />

      <div className="card card-full">
        <div className="card-body">
          <DocsHeader />

          {funds[0].updates.map((filteredUpdate) =>
             (
                <MonthlyUpdate
                key={filteredUpdate.id}
                updateId={filteredUpdate.id}
                month={filteredUpdate.month}
                year={filteredUpdate.year}
                date={filteredUpdate.date}
                />
            )
            )}
        </div>
      </div>
    </div>
  </div>
)
}


export default Updates;