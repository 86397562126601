import React from "react";

const FundTable = ({ filteredFund, category, heading }) => {
return(


<div className="card card-full table-card">
<div className="card-body">
<h4 className="mb-4">{heading}</h4>
<div className="">
{filteredFund.fundmeta.filter(fundmetum => fundmetum.category.toString() === category).map(filteredData => {
    if (filteredData >= '0') return(

  
  <div className="aic d-flex table-cell">
            <h6 className="left">{filteredData.title}</h6>
            <div className="right">
                
                <p className="">{filteredData.data}</p>
            </div>
        </div>

) 
})}
</div>
</div>
</div>

)
}

export default FundTable;