import React  from 'react';
import {useContext} from 'react'
import {UserContext} from '../context/UserContext'
import { jsPDF } from "jspdf";
import { font } from "../fonts/Ambit/Ambit-Regular-normal.js";
import { boldfont } from "../fonts/Ambit/Ambit-Bold-normal.js";

const Contract = (props) => {

    const {user} = useContext(UserContext);

	function GenerateSimplePDF() {
    
		const doc = new jsPDF();

		doc.addFileToVFS("Ambit-Regular-normal.ttf", font);
    	doc.addFont("Ambit-Regular-normal.ttf", "Ambit-Regular", "normal");

		doc.addFileToVFS("Ambit-Bold-normal.ttf", boldfont);
    	doc.addFont("Ambit-Bold-normal.ttf", "Ambit-Bold", "normal");
	
		doc.setFont("Ambit-Bold", "normal");
	 
		doc.setFontSize(10)

		doc.setTextColor(104, 104, 221);

		doc.text("Inschrijving Capler Private Equity", 20, 50);

		doc.setFontSize(10)

		doc.setTextColor(0, 0, 0);

		doc.text("Ondergetekende,", 20, 60);

		doc.setFont("Ambit-Regular", "normal");
	
		doc.text(user.first_name+" "+user.last_name, 20, 64);

		doc.text("Geboortedatum: "+user.id_date_of_birth, 20, 68);

		doc.text("BSN: "+user.id_bsn, 20, 72);
	 
		doc.text("1.", 20, 88);

		doc.text("Te willen investeren in het Capler Private Equity Fund-of-Funds (\u0022Fonds\u0022), een subfonds van het Alpha High Performance Fund, met een eenmalig bedrag van €"+localStorage.getItem('setAmount')+" (\u0022Totaalbedrag\u0022). Deze investering is niet gedaan als een lijfrente (pensioen beleggen) en geniet daarom geen fiscaal voordeel.",30,88, {
			maxWidth: 150,
			align: 'left'
		});

		doc.text("2.", 20, 109);

		doc.text("De verstrekte persoonlijke gegevens en het beleggersprofiel naar waarheid te hebben ingevuld en wijzigingen van persoonlijke gegevens direct te zullen doorgeven aan het Fonds of te wijzigen via www.capler.nl.",30,109, {
			maxWidth: 150,
			align: 'left'
		});

		doc.text("3.", 20, 126);

		doc.text("Het Prospectus en het EID zorgvuldig gelezen te hebben en de voorwaarden en risico\u2019s die hierin beschreven staan te accepteren.",30,126, {
			maxWidth: 150,
			align: 'left'
		});

		doc.text("4.", 20, 138);

		doc.text("Te begrijpen dat van het Totaalbedrag de instapkosten worden ingehouden (\u0022Instapkosten\u0022), zoals gespecificeerd in het Prospectus.",30,138, {
			maxWidth: 150,
			align: 'left'
		});

		doc.text("5.", 20, 150);

		doc.text("Te begrijpen dat alleen het resterende bedrag (Totaalbedrag minus Instapkosten) wordt belegd in het Fonds.",30,150, {
			maxWidth: 150,
			align: 'left'
		});

		doc.text("6.", 20, 162);

		doc.text("Te begrijpen dat het resterende bedrag wordt omgezet naar participaties en dat het aantal verstrekte participaties afhangt van de waarde van het Fonds op het moment van uitgifte, wat op zijn beurt afhangt van diverse factoren, waaronder marktomstandigheden.",30,162, {
			maxWidth: 150,
			align: 'left'
		});

		doc.text("7.", 20, 178);

		doc.text("Te begrijpen en te accepteren: i) dat het subfonds alleen geschikt is voor beleggers die minimaal 8 jaar kunnen en willen investeren; ii) dat het pas na 3 jaar (lock-up), en daarna slechts eenmaal per kwartaal mogelijk is om uit te stappen, tegen voorwaarden conform het Prospectus; iii) wat de risico\u2019s zijn van het subfonds en van Private Equity beleggingen.",30,178, {
			maxWidth: 150,
			align: 'left'
		});

		doc.text("Het is mogelijk het Totaalbedrag in zijn geheel te verliezen. De participaties in het Fonds zijn niet publiekelijk verhandelbaar.",20,204, {
			maxWidth: 160,
			align: 'left'
		});

		doc.text("De inschrijving is pas definitief nadat het Totaalbedrag op de rekening van het Fonds is ontvangen en het Fonds schriftelijk heeft ingestemd met de toetreding tot het Fonds.",20,215, {
			maxWidth: 160,
			align: 'left'
		});

		doc.setFont("Ambit-Bold", "normal");

		doc.text("Datum:", 20, 241);

		doc.setFont("Ambit-Regular", "normal");

		doc.text(new Date().toLocaleString("nl-NL", { day: "2-digit" })+"-"+new Date().toLocaleString("nl-NL", { month: "2-digit" })+"-2023", 60, 241);

		doc.setFont("Ambit-Bold", "normal");

		doc.text("Handtekening:", 20, 249);


	

		

		const pageCount = doc.internal.getNumberOfPages();
		for(let i = 0; i < pageCount; i++) { 
		doc.setPage(i)

		const img = new Image()
		
		img.src = '../assets/img/capler-logo-doc.png'
		
		doc.addImage(img, 'png', 20, 20, 25, 6)

		doc.setFont("Ambit-Bold", "normal");
	 
		doc.setFontSize(9)
	
		doc.text("Pagina", 140, 20);

		doc.text("Onderwerp", 140, 24);

		doc.text("Datum", 140, 28);

		doc.setFont("Ambit-Regular", "normal");

		doc.text('Inschrijving', 164, 24);

		doc.text(new Date().toLocaleString("nl-NL", { day: "2-digit" })+"-"+new Date().toLocaleString("nl-NL", { month: "2-digit" })+"-2023", 164, 28);
	
		doc.text(doc.internal.getCurrentPageInfo().pageNumber+" van "+ pageCount, 164, 20);
		}
		doc.save('inschrijving'+"_"+user.first_name+".pdf");
		//doc.output('dataurlnewwindow', props.name+"_"+user.first_name+".pdf");
	  }

    return (

	
		<div className="file">
			<div className="d-flex aic">
		<h3><i className="bi bi-file-text"></i> </h3>
		<p>Voorbeeldcontract {props.profile.profile_name}</p>
		</div>
			
		<button className="btn" onClick={GenerateSimplePDF}>Bekijk</button>
	  </div>


    )
}

export default Contract;


