import React  from 'react';
import {useState, useContext, useEffect} from 'react'
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/UserContext'
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import axios from 'axios';

export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
  });


const Options = () => {

    const {user, text, loggedInCheck} = useContext(UserContext);
    const [wait, setWait] = useState(false);
    const navigate = useNavigate();
    const [Validated, setValidated] = useState(false);

    const [Manual, setManual] = useState(false);

    const handleMethodChange = (event) => {
        const value = event.target.value;
        if (value === 'manual') {
          setManual(true);
        } else {
          setManual(false);
        }
      };
   

    return (

        <>
        <h3 className="text-left">Prettig kennismaken!</h3>
                    <p>Voordat u kunt beleggen, willen we uw identiteit verifiëren. Selecteer de manier waarop u zich wilt identificeren.</p>
        
      
                    <div className="profiles mw-1100">

<label>
    <input 
    type="radio" 
    name="method" 
    value='digital' 
    className="card-input-element" 
    onChange={handleMethodChange} // Call the function on change
    defaultChecked
    />
    <div className="card">
        <div className="card-body">
            <h5>Digitaal</h5>
    
            <p>Online identificeren</p>
     
            <div className="badge-container d-flex mt-3">
            {/*<span class="badge text-bg-light mw-fc"><i class="bi bi-check-all"></i> Veilig</span>*/}
            
            </div>
            <i className="bi bi-check-circle-fill"></i>
            <i className="bi bi-circle-fill"></i>
        </div>
    </div>
 </label>

 <label>
    <input 
    type="radio" 
    name="method" 
    value='manual' 
    className="card-input-element" 
    onChange={handleMethodChange} // Call the function on change
  
    />
    <div className="card">
        <div className="card-body">
            <h5>Manueel</h5>
            <p>Scans uploaden</p>
            <div className="badge-container d-flex mt-3">
             {/*<span class="badge text-bg-light mw-fc"><i class="bi bi-check-all"></i> Veilig</span>*/}
            </div>
            <i className="bi bi-check-circle-fill"></i>
            <i className="bi bi-circle-fill"></i>
        </div>
    </div>
 </label>

 </div>

 <div className="card-btn d-flex">

  {Manual ? (
    <Link to="/identification/manual">
      <Button type="submit" variant="primary">
        Volgende
      </Button>
    </Link>
  ) : (
    <Link to="/identification/onfido">
      <Button type="submit" variant="primary">
        Volgende
      </Button>
    </Link>
  )}
</div>
          


      </>
        
    )
}

export default Options;


