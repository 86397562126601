import React from "react";
import {useState,useContext} from 'react';
import {UserContext} from '../../context/UserContext'
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { NumericFormat } from 'react-number-format';



const Completion = ({qn, setQn, submitQn, props}) => {

  const {text} = useContext(UserContext);
  const [validated, setValidated] = useState(false);
  const [checked, setChecked] = useState({});
  const persBus = localStorage.getItem('persBus');

  const handleChange = (e) => {
    setChecked({
      ...checked,
      [e.target.name]: e.target.checked
    });
    setQn((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const qnSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    
    setValidated(true);
  };

    return (

      <>

      <h4>Voltooi uw beleggersprofiel</h4>
      <p>Beantwoord nog enkele vragen om uw profiel succesvol aan te maken.</p>

      <Form className="large" noValidate validated={validated} onSubmit={qnSubmit}>
      
      <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Form.Label>Hoeveel verwacht u nu te investeren? (Dit is geen toezegging)</Form.Label>
            <Form.Text className="text-muted">
          U kunt participeren vanaf €5.000. 
        </Form.Text>
            <span className="input-symbol-euro">
            <NumericFormat 
              onChange={handleChange} 
              name='qn_amount_now' 
              className="form-control"  
              decimalScale={2} 
              value=""
              decimalSeparator={text.decimal_seperator} 
              allowedDecimalSeparators={text.decimal_seperator} 
              allowLeadingZeros 
              required
              thousandSeparator={text.thousand_seperator} 
              />
        </span>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Form.Label>Hoeveel verwacht u aankomend jaar nog meer te investeren? (Dit is geen toezegging)</Form.Label>
            <Form.Text className="text-muted">
          Na uw eerste belegging, kunt u participeren vanaf €1.000. 
        </Form.Text>
            <span className="input-symbol-euro">
            <NumericFormat 
              onChange={handleChange} 
              name='qn_amount_year' 
              className="form-control"  
              decimalScale={2} 
              value=""
              decimalSeparator={text.decimal_seperator} 
              allowedDecimalSeparators={text.decimal_seperator} 
              allowLeadingZeros 
              required
              thousandSeparator={text.thousand_seperator} 
              />
              </span>

            <Form.Control.Feedback></Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Form.Label>Wat is uw verwachte beleggingshorizon?</Form.Label>
           

        <Form.Select 
            aria-label="Default select example"
            name='qn_horizon'
              onChange={handleChange} 
              value={qn.qn_horizon}
              required
            >
      <option>Maak een keuze</option>
      <option value="1-3">1-3 jaar</option>
      <option value="4-8">4-8 jaar</option>
      <option value="9-12">9-12 jaar</option>
      <option value="13+">13+ jaar</option>
    
      </Form.Select>

          </Form.Group>
        </Row>



      
       {localStorage.getItem('persBus') === 'pers' && (<>
       <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Form.Label>Hoe heeft u het vermogen dat u zult investeren verdiend?</Form.Label>
            <Form.Text className="text-muted">
          Meerdere antwoorden mogelijk
        </Form.Text>
            
     
        <Form.Check
          type="checkbox"
          name="qn_source_funds"
          value="labor"
          onChange={handleChange}
          label="Loon"
        />
      
        <Form.Check
          type="checkbox"
          name="qn_source_funds"
          value="business"
          onChange={handleChange}
          label="Onderneming"
        />
     
        <Form.Check
          type="checkbox"
          name="qn_source_funds"
          value="investment"
          onChange={handleChange}
          label="Belegging"
        />

        <Form.Check
          type="checkbox"
          name="qn_source_funds"
          value="inherit"
          onChange={handleChange}
          label="Erfenis/schenking"
        />
      
        <Form.Check
          type="checkbox"
          name="qn_source_funds"
          value="gambling"
          onChange={handleChange}
          label="Kansspelen of gaming (al dan niet online)"
        />
     
        <Form.Check
          type="checkbox"
          name="qn_source_funds"
          value="crypto"
          onChange={handleChange}
          label="Virtuele valuta (inc. mining, handel, etc.)"
        />
       

    
            <Form.Control.Feedback></Form.Control.Feedback>
          </Form.Group>
        </Row>
        
        

        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Form.Label>Is uw vermogen herkomstig uit een ander land dan Nederland?</Form.Label>
          
            <Form.Select 
            aria-label="Default select example"
            name='qn_source_funds_other'
              onChange={handleChange} 
              value={qn.qn_source_funds_other}
              required
            >
      <option >Maak een keuze</option>
      <option value="NO">Nee</option>
      <option value="YES">Ja</option>
    </Form.Select>
            <Form.Control.Feedback></Form.Control.Feedback>
          </Form.Group>
        </Row>

        {qn.qn_source_funds_other === 'YES' && (

        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Form.Label>Land van herkomst vermogen?</Form.Label>
            <Form.Control
              name='qn_source_funds_country'
              onChange={handleChange} 
              value={qn.qn_source_funds_country}
              required
              type="text"
            />
            <Form.Control.Feedback></Form.Control.Feedback>
          </Form.Group>
        </Row>

        )}    

        </>)}

        
      

        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Form.Label>Bent u belastingplichtig buiten Nederland?</Form.Label>
          
            <Form.Select 
            aria-label="Default select example"
            name='qn_tax_country'
              onChange={handleChange} 
              value={qn.qn_tax_country}
              required
            >
      <option >Maak een keuze</option>
      <option value="NO">Nee</option>
      <option value="YES">Ja</option>
    </Form.Select>
            <Form.Control.Feedback></Form.Control.Feedback>
          </Form.Group>
        </Row>

        {qn.qn_tax_country === 'YES' && (

<Row className="mb-3">
  <Form.Group as={Col} md="12" controlId="validationCustom01">
    <Form.Label>Bent u belastingplichtig in de Verenigde Staten?</Form.Label>
    <Form.Select 
            aria-label="Default select example"
            name='qn_us_person'
              onChange={handleChange} 
              value={qn.qn_us_person}
              required
            >
      <option >Maak een keuze</option>
      <option value="NO">Nee</option>
      <option value="YES">Ja</option>
    </Form.Select>
    <Form.Control.Feedback></Form.Control.Feedback>
  </Form.Group>
</Row>

)}

        {qn.qn_us_person === 'NO' && (

        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Form.Label>In welk land bent u belastingplichtig?</Form.Label>
            <Form.Control
              name='qn_tax_country_other'
              onChange={handleChange} 
              value={qn.qn_tax_country_other}
              required
              type="text"
            />
            <Form.Control.Feedback></Form.Control.Feedback>
          </Form.Group>
        </Row>

)}
        
        
       

        <Button ref={submitQn} className='btn-secondary' style={{ display: 'none' }} type="submit">Bevestig vragenlijst</Button>
        
        

      </Form>
       

      </>

    )
}

export default Completion;
