import React  from 'react';
import {createContext, useState, useEffect} from 'react'
import LoadingSpinner from "../components/LoadingSpinner";
import axios from 'axios'

export const UserContext = createContext("");

export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
});

export const UserContextProvider = ({children}) => {
    
    const [theUser, setUser] = useState(null);
    const [theText, setText] = useState(null);
    const [theProfile, setProfile] = useState(null);
    const [theNotifications, setNotifications] = useState(null);
    const [theDocuments, setDocuments] = useState(null);
    const [theFunds, setFunds] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [wait, setWait] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);

    

    const registerUser = async ({first_name,last_name,email,password}) => {
        setWait(true);
        try{
            const {data} = await Axios.post('register.php',{
                first_name,
                last_name,
                email,
                password 
            });
            setWait(false);
            return data;
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Server Error!'};
        }
    }

    const updateUser = async ({first_name,last_name,email}) => {
        setWait(true);
        
        try{
            const {data} = await Axios.post('user/update.php',{
                first_name,
                last_name,
                email
            });
            await loggedInCheck();
            setWait(false);
            return data;
            

        }
        catch(err){
            setWait(false);
            return {success:0, message:'Server Error!'};
        }
      
        
    }

    const verificationUser = async ({password,password_repeat,veri_string}) => {
        setWait(true);
        try{
            const {data} = await Axios.post('verification.php',{
                password,
                password_repeat,
                veri_string 
            });
            setWait(false);
            return data;
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Server Error!'};
        }
    }

    const loginUser = async ({email,password}) => {

       
        setWait(true);
        try{
            const {data} = await Axios.post('login.php',{
                email,
                password 
            });
            if(data.success && data.token){
                localStorage.setItem('loginToken', data.token);
                setWait(false);
                setTimeout (() => {
                    localStorage.removeItem('loginToken');
                    localStorage.clear();
                    setUser(null);
                    setIsLoading(false); 
                   
                    alert("Uw sessie is verlopen.");
                }, 3600000);
                return {success:1};
                
                
            }
            setWait(false);
            localStorage.removeItem('loginToken');
            return {success:0, message:data.message};
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Server Error!'};
        }

    }

    const forgotUser = async ({email}) => {
        setWait(true);
        try{
            const {data} = await Axios.post('forgot.php',{
                email
            });
            setWait(false);
            return data;
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Server Error!'};
        }
    }

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    const loggedInCheck = async () => {
        setIsLoading(true);
        const loginToken = localStorage.getItem('loginToken');
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
        if(loginToken){
            
            const {data} = await Axios.get('getUser.php');
            if(data.success && data.user){
                setUser(data.user);
                setText(data.text);
                setProfile(data.profile);
                setNotifications(data.notifications);
                setDocuments(data.documents);
                setFunds(data.funds);
                

                await timeout(1000);
                setIsLoading(false) 
                
                return;
            }
            
            localStorage.removeItem('loginToken');
            localStorage.clear();
            setUser(null);
            await timeout(1000);
            setIsLoading(false) 
          
        }
        localStorage.removeItem('loginToken');
        localStorage.clear();
        setUser(null);
        setIsLoading(false) 
    }

    useEffect(() => {
        async function asyncCall(){
            await loggedInCheck();
        }
        asyncCall();
    },[]);

    const logout = () => {
        localStorage.clear();
        setUser(null);
      
    }
    const getText = async () => {
    const {text} = await Axios.get('getText.php');
    }


    



    return (
        
        <UserContext.Provider value={{
            registerUser,
            updateUser,
            loginUser,
            verificationUser,
            forgotUser,
            wait,
            user:theUser,
            text:theText,
            profile:theProfile,
            notifications:theNotifications,
            documents:theDocuments,
            funds:theFunds,
            getText,
            loggedInCheck,
            logout
            }}>
            {isLoading ? <LoadingSpinner /> : children}
            
            
        </UserContext.Provider>
    );

}

export default UserContextProvider;