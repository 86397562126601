import React  from 'react';
import {useState,useContext, useEffect} from 'react'
import {UserContext} from '../../context/UserContext'
import {Button} from 'react-bootstrap';
import Redirecting from '../../components/Redirecting';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Topbar from '../../components/dashboard/Topbar';
import Sidebar from '../../components/dashboard/Sidebar';
import DataCheck from '../../pages/identification/DataCheck';
import ExtraForm from '../../pages/identification/ExtraForm';
export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
  });


const Result = () => {

    const {user, text} = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                
                // Wait for 5 seconds (5000 milliseconds)
                await new Promise(resolve => setTimeout(resolve, 5000));
    
                const loginToken = localStorage.getItem('loginToken');
                Axios.defaults.headers.common['Authorization'] = 'Bearer ' + loginToken;
                
                const { data } = await Axios.post('user/get_id_status.php', {
                    user_id: user.user_id
                }).then(response => response.data);
                
                setResult(data);
            } catch (err) {
                return { success: 0, message: 'Server Error!' };
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchData();
    }, []);
      
    if (isLoading) {
        return <Redirecting />;
      }

    return (


        <div id="dashboard">

        <Topbar/>   
        <Sidebar/> 

        <div className="board">

            
        
        <div className="card card-large">
                <div className="card-body">

                    {user.id_progress === 'COMPLETED' ?
                 
                      <DataCheck result={result} />
                   
                    : user.id_status_report === 'REJECTED_ONCE' ?
                    <>
                    {user.id_status_reasons === 'document_expired' ?
                    <h3 className="text-left">Uw document is niet meer geldig</h3>
                    : user.id_status_reasons === 'unsupported_document' ?
                    <h3 className="text-left">Uw document wordt niet ondersteund</h3>
                    : user.id_status_reasons === 'is_copy' ?
                    <h3 className="text-left">Uw document lijkt niet authentiek</h3>
                    : user.id_status_reasons === 'no_document' ?
                    <h3 className="text-left">Uw document lijkt te ontbreken</h3>
                    :
                    <h3 className="text-left">We konden uw document niet lezen</h3>
                    }
                    <p>Hierdoor hebben we uw identiteit helaas niet kunnen verifëren. Probeer het nog eens.</p>
                    

                    <Link className="card-btn" to='/invest'><Button variant="primary">Probeer nog eens</Button></Link>
                    </>
                    : user.id_status_report === 'REJECTED_TWICE' ?
                   
                    
                   <ExtraForm/>
                   
                  
                     : user.id_status === 'REJECTED' ?
                     <>
                     <h3 className="text-left">Uw identificatie is mislukt.</h3>
                     
                     <p>Blieblabloe</p>
                    
                     <Link className="card-btn" to='/invest'><Button variant="primary">Upload scan</Button></Link>
                     </>
                     :
                     <>
                     <h3 className="text-left">Uw identificatie is mislukt.</h3>
                     
                     <p>Blieblabloe</p>
                    
                     <Link className="card-btn" to='/invest'><Button variant="primary">Upload scan</Button></Link>
                     </>
                     }

                   

                 
                   
                    
                     
                </div>
            </div>
            
        
        </div>
        
        
    </div>
        
        
    )
}

export default Result;


