import React  from 'react';
import { Link } from 'react-router-dom';
import {useContext, useState} from 'react'
import Topbar from '../../components/dashboard/Topbar';
import Sidebar from '../../components/dashboard/Sidebar';
import {UserContext} from '../../context/UserContext'

import FundHeader from './FundHeader';
import FundIntro from './FundIntro';
import FundTable from './FundTable';
import Companies from './Companies';
import CTA from './CTA';
import Basket from './Basket';
import Breadcrumb from 'react-bootstrap/Breadcrumb';




const Fund = (props) => {

    const {funds, text, user} = useContext(UserContext);  
const numDescending = [...funds].sort((a, b) => b.order_date - a.order_date); 
const [fund, setFunds] = useState([]);

const pathParts = window.location.pathname.split('/funds/');

const fundName = pathParts[1].split('/')[0];

function loadFund(e) {
    const item = e.target.value;
    setFunds([...funds]);
  }

    return (

        <div id="dashboard">

            <Topbar/>   
            <Sidebar/> 

   

                
            {funds.filter(fund => fund.public_id.toString() === fundName).map(filteredFund => {


if (filteredFund >= '0') return(
<div className="board" key={filteredFund.public_id}>

    <Breadcrumb className="breadcrumbs">
       
        
        <Breadcrumb.Item active>{filteredFund.name}</Breadcrumb.Item>
        </Breadcrumb>

  <FundHeader filteredFund={filteredFund} /> 

  <Basket filteredFund={filteredFund} /> 

  {filteredFund.fundmeta && filteredFund.fundmeta.filter(fundmetum => fundmetum.category.toString() === 'fund').slice(0, 1).map(filteredData => {
    if (filteredData >= '0') return(
      <FundTable filteredFund={filteredFund} category={filteredData.category} heading={"Fondsoverzicht"} />
    ) 
      })}



<FundIntro filteredFund={filteredFund} category='intro' /> 
  
    
    

  {filteredFund.fundmeta && filteredFund.fundmeta.filter(fundmetum => fundmetum.category.toString() === 'manager').slice(0, 1).map(filteredData => {
    if (filteredData >= '0') return(
      <FundTable filteredFund={filteredFund} category={filteredData.category} heading={"Beheerder"} />
    ) 
      })}



{user.has_investments === 'yes' ?
<Companies filteredFund={filteredFund} heading={"Een greep uit de portefeuille"} />
  :
  <CTA filteredFund={filteredFund} heading={"Een greep uit de portefeuille"} />
  }

</div>

) 


})}

                    
                    

          
            
        </div>

    )
}

export default Fund;


