import React  from 'react';
import {useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import {UserContext} from '../context/UserContext';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Banner from './Banner';

const Register = () => {
    const {registerUser, wait} = useContext(UserContext);
    const [errMsg, setErrMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [formData, setFormData] = useState({
        first_name:'',
        last_name:'',
        email:''
    });

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value.replace(/[^a-z\s-]/gi, '')
        })
    }

    const onChangeInputTwo = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if(!Object.values(formData).every(val => val.trim() !== '')){
            setSuccessMsg(false);
            setErrMsg('Please Fill in all Required Fields!');
            return;
        }

        const data = await registerUser(formData);
        if(data.success){
            e.target.reset();
            setSuccessMsg('Uw aanmelding is gelukt. We hebben u een e-mail gestuurd om uw account te activeren.');
            setErrMsg(false);
        }
        else if(!data.success && data.message){
            setSuccessMsg(false);
            setErrMsg(data.message);
        }
        
    }

    return (
        <div className="splitter d-flex">
            <Banner/>
            
            <div className="split form">
                <div className="ext">
                    <h6>Al een account?</h6>
                    <Link className="link" to="/login">Inloggen bij Capler</Link>
                </div>
                <div className="auth">
                    <h3>Aanmelden</h3>
                    <Form onSubmit={submitForm}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1"><i className="bi bi-person"></i></InputGroup.Text>
                            <Form.Control type="text" name="first_name" onChange={onChangeInput} placeholder="Voornaam" id="first_name" value={formData.first_name} required />
                        </InputGroup>
                        <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><i className="bi bi-person-vcard"></i></InputGroup.Text>
                            <Form.Control type="text" name="last_name" onChange={onChangeInput} placeholder="Achternaam" id="last_name" value={formData.last_name} required />
                        </InputGroup>
                        <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><i className="bi bi-envelope"></i></InputGroup.Text>
                            <Form.Control type="email" name="email" onChange={onChangeInputTwo} placeholder="E-mail" id="email" value={formData.email} required />
                        </InputGroup>
                        <p className="form-disc">
                    Door een account aan te maken, ga je akkoord met onze <a target="_blank" href="https://capler.nl/files/documents.php?doc=disclaimer">disclaimer</a> en ons <a target="_blank" href="https://capler.nl/files/documents.php?doc=privacy">privacy</a> & <a target="_blank" href="https://capler.nl/files/documents.php?doc=privacy">cookiebeleid</a>.
                    </p>
                    {errMsg && <div className="err-msg">{errMsg}</div>}
                    <button className="btn btn-primary" type="submit" disabled={wait}>Aanmelden bij Capler</button>
                        {successMsg && <div className="success-msg">{successMsg}</div>}
                        
                        
                        
                        
                    </Form>
                   
                </div>
            </div>
        </div>
        
       
    )
}

export default Register;
