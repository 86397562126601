import React  from 'react';
import {useContext} from 'react'
import {UserContext} from '../../../context/UserContext'
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Onboarding = () => {

    const {user, text} = useContext(UserContext);
    


    return (
        
            <div className="card card-large">
                <div className="card-body">
                    <h3 className="text-left">Hallo {user.first_name}!</h3>
                    
                    <p>Welkom in uw persoonlijke dashboard. Hier kunt u uw persoonlijke documenten vinden en straks ook alle informatie over uw beleggingen.</p>
                   {!user.id_first_name ?
                    <Link className="card-btn" to='/invest'><Button variant="primary">Start uw eerste belegging</Button></Link>
                    :
                    <Link className="card-btn" to='/invest'><Button variant="primary">Vervolg uw eerste belegging</Button></Link>
                    }
                   {/*
                    <Link className="card-btn" to='/identification/loading'><Button variant="primary">Temp test</Button></Link>
                    <Link className="card-btn" to='/identification/result'><Button variant="primary">Temp result</Button></Link>
                    */}
                    
                     
                </div>
            </div>
        
        
    )
}

export default Onboarding;


