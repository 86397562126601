import React from "react";
import {useState,useContext} from 'react';
import {UserContext} from '../../context/UserContext'
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

function Uncheck() {
  return <i className="bi bi-square"></i>;
}

function Check() {
  return <i className="bi bi-check-square"></i>;
}

const Personaldata = (props) => {

  const {user} = useContext(UserContext);

  const persBus = localStorage.getItem('persBus');

  const [validated, setValidated] = useState(false);

  function formControl(event) {
    props.onPersonVerified(false);
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      props.onPersonVerified(false);
      setValidated(false);
    } else {
      props.onPersonVerified(true);
      setValidated(true);
      localStorage.setItem('profile_name', getFirstChar(user.id_first_name)+' '+user.id_last_name);
    }
    event.preventDefault();
    setValidated(true);
    
    
  };

  const getFirstChar = (str) => {
    const firstChars = str
      .split(" ")
      .map((word) => word[0])
      .join("");

    return firstChars;
  };

    return (

      <>

      <h4>Uw persoonsgegevens</h4>
      <p>Onderstaande gegevens zijn afkomstig uit uw legitimatie. Vul deze aan waar nodig, zoals vermeld in uw legitimatiebewijs.</p>



        <Form className="large" noValidate validated={validated} onSubmit={handleSubmit}>
        
        <Row className="">
          <Form.Group as={Col} md="3" controlId="validationCustom01">
            <Form.Label>Voorletters</Form.Label>
      
            <Form.Control
              required
              onChange={formControl}
              type="text"
           
              defaultValue={getFirstChar(user.id_first_name)}
            />
            <Form.Control.Feedback>Bevestigd</Form.Control.Feedback>
          </Form.Group>
          
          
        </Row>
        
        <Row className="">
          <Form.Group as={Col} md="5" controlId="validationCustom01">
            <Form.Label>Voornamen</Form.Label>
      
            <Form.Control
              required
              onChange={formControl}
              type="text"
         
              defaultValue={user.id_first_name}
            />
            <Form.Control.Feedback>Bevestigd</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="7" controlId="validationCustom02">
            <Form.Label>Achternaam</Form.Label>
         
            <Form.Control
              required
              onChange={formControl}
              type="text"
      
              defaultValue={user.id_last_name}
            />
            <Form.Control.Feedback>Bevestigd</Form.Control.Feedback>
          </Form.Group>
          
        </Row>

        <Row className="">
          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Label>Geboortedatum</Form.Label>
            
            <Form.Control
              required
              onChange={formControl}
              type="date"
              defaultValue={user.id_date_of_birth}
            />
            <Form.Control.Feedback>Bevestigd</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="validationCustom01">
            <Form.Label>Geslacht</Form.Label>
            
            <Form.Select defaultValue={user.id_sex} aria-label="Default select example" onChange={formControl}>
            <option disabled>Geslacht</option>
            <option value="f">Vrouw</option>
            <option value="m">Man</option>
            <option value="o">Anders</option>
          </Form.Select>
            <Form.Control.Feedback>Bevestigd</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="5" controlId="validationCustom02">
            <Form.Label>Nationaliteit</Form.Label>
         
            <Form.Control
              required
              onChange={formControl}
              type="text"
              defaultValue="NL"
            />
            <Form.Control.Feedback>Bevestigd</Form.Control.Feedback>
          </Form.Group>

          
          
        </Row>

        <Button className='btn-secondary' type="submit">{validated === true ? <Check/> : <Uncheck/>} Bevestig persoonsgegevens</Button>
       
      </Form>

      </>

    )
}

export default Personaldata;
