import React  from 'react';
import {useState, useContext} from 'react'
import {UserContext} from '../../context/UserContext'
import Form from 'react-bootstrap/Form';
import axios from 'axios';
export const Axios = axios.create({
  baseURL: 'https://admin.capler.nl/',
});


const Test = () => {

    const {user} = useContext(UserContext);
    const [wait, setWait] = useState(false);
    const [testValue, setTestValue] = useState([]);


  const handleSign = async (e) => {
    e.preventDefault();
    try{
        const loginToken = localStorage.getItem('loginToken');
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
        
        const {data} = await Axios.post('test/bearer.php',{
            test_value:'bla',
            user_id:user.user_id
        }).then(response => response.data).then((d) => setTestValue(d));
    
    
       
  
    }
    catch(err){
        setWait(false);
        return {success:0, message:'Server Error!'};
    }
  }


return (
    
    <div className="board">

  
        
        <p>Test works!</p>
        <Form className="full ml-auto" onSubmit={handleSign}>
        {testValue.two && (
            <p>{testValue.two}</p>
        )}      
                            <button
                            className="btn ml-auto float-end btn-primary" 
                            type="submit"
            
                            >
                            Ondertekening hervatten
                            </button>
                            </Form>

       


    </div>
)


}

export default Test;


