import React from "react";
import {useState,useContext,useEffect} from 'react';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import axios from 'axios'
export const Axios = axios.create({
  baseURL: 'https://admin.capler.nl/',
});


const Businessdata = (props, bus) => {

  const [validated, setValidated] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [kvk, setKvk] = useState([]);
    const [searching, setSearching] = useState(false);
  const [wait, setWait] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
   
});



const onChangeInput = (e) => {
    setFormData({
        ...formData,
        [e.target.name]:e.target.value
    })
}


const handleKvkEntry = async (e) => {
  setLoading(false);
  setSearching(true); 
  e.preventDefault();

  const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    e.preventDefault();
    setValidated(true);
    
  if(!Object.values(formData).every(val => val.trim() !== '')){
      
      return;
  }

  const data = await findEntry(formData);
  if(data.success){
      e.target.reset();
      setLoading(false);
      
  }
  else if(!data.success && data.message){
     
  }
  
}

const findEntry = async ({kvk_no}) => {
  setSearching(true); 
  localStorage.setItem('kvkNumber', kvk_no);
  setWait(true);
  try{
      const {data} = await Axios.post('getKVKentry.php',{
        kvk_no
      }).then(response => response.data).then((d) => setKvk(d));
      localStorage.setItem('profile_name', kvk.businessName);
      setLoading(false);
      setSearching(false);

  }
  catch(err){
      setWait(false);
      return {success:0, message:'Server Error!'};
  }
  setLoading(false);
  
  
}

useEffect(() => {
  if (kvk && (kvk.businessName || kvk.response) ) {
        setSearching(false); 
      } else {
       
      }
});
  

    return (

      <>

      <h4>Uw bedrijfsgegevens</h4>
      <p>Vind en selecteer uw bedrijf.</p>

      

        <Form className="large" noValidate onSubmit={handleKvkEntry}>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>KVK Nummer</Form.Label>
            <Form.Text className="text-muted">
            Zoals geregistreerd
          </Form.Text>
            <Form.Control
              name="kvk_no"
              required
              type="number"
              placeholder=""
              onChange={onChangeInput}
              onKeyDown={ (evt) => (evt.key === 'e' || evt.key === 'E' || evt.key === '.') && evt.preventDefault() }
            />
             
                 
           
          </Form.Group>
          

         </Row>
        { searching === true ?
         <Button className='btn-secondary' disabled>
         <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          
        />  Register doorzoeken
        </Button>
        :
         <Button className='btn-secondary' type="submit"><i className="bi bi-search"></i> Doorzoek register</Button>
        }
      </Form>

      {loading &&
          <Spinner animation="border" />}
      {kvk.businessName ?
        <>
      <h5>Selecteer uw bedrijf ter verificatie</h5>
      <div className="profiles kvk">
      
        
        
       <label>
       <input 
       type="radio" 
       name="profile" 
       value={kvk.kvkNumber}
       className="card-input-element" 
       onChange={(e)=>{props.onCompanySelected(e.target.value)}} 
       
       />
      <div className="card">
        <div className="card-body">
         <h4>{kvk.businessName}</h4>
         <div className="checks">
         
         
         {kvk.addresses ?
        <>
          {kvk.addresses.filter(address => address.type === 'CorrespondanceAddress' || address.type === 'VisitAddress').map(filteredAddress => (
            <p className=""><i className="bi bi-geo-alt"></i> {filteredAddress.street} {filteredAddress.houseNumber}, {filteredAddress.postalCode}, {filteredAddress.city}</p>
          ))}
        </>
        :
        <>
        </>
      }

        <p><i className="bi bi-patch-check"></i> {kvk.legalForm}</p>
        <p><i className="bi bi-file-text"></i> {kvk.kvkNumber}</p>
        </div>
         <i className="bi bi-check-circle-fill"></i>
        </div>
      </div>
      </label>
      
     
      </div>
      </>
      : kvk.response === 'none' ?
      <>
      <p>We konden helaas geen verbinding maken met het handelsregister. Voer uw handelsnaam in.</p>
      <Form className="large" noValidate >
        <Row className="mb-3">
          <Form.Group as={Col} md="6">
             
             <Form.Label>Handelsnaam</Form.Label>
            <Form.Control
              name="bus_name"
              required
              type="text"
              placeholder=""
              onChange={props.onChangeBusName}
              value={bus.bus_name}
            />
           
          </Form.Group>
          

         </Row>

      </Form>
      </>
      :
      <>
      
      </>
       }
      </>

    )
}

export default Businessdata;
