import React from "react";
import {useState, useContext, useEffect} from 'react';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';



const Child = ({child, setChild, childRef}) => {

  const [validated, setValidated] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [zipValid, setZipValid] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [successMsg, setSuccessMsg] = useState(false);

  const persBus = localStorage.getItem('persBus');
  const relation = localStorage.getItem('relation');

  const handleChange = (e) => {
    setChild((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
    
  };
  

  const formOnChageHandler = (e) => {
    
  };

  const childSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    
    setValidated(true);
  };

    return (

      <>

      <h4>Persoonsgegevens minderjarige participant</h4>

      {relation === 'child' && (
        <p>Is het kind waarvoor u wilt beleggen ouder dan achttien jaar, dan dient het kind zich zelf te registreren bij Capler ter participatie.</p>
      )}
      
        
        <Form className="large" noValidate onChange={formOnChageHandler} validated={validated} onSubmit={childSubmit}>
        <Row className="mb-3">

        <Form.Group as={Col} md="5" controlId="validationCustom01">
            <Form.Label>Voornamen</Form.Label>
            
            <Form.Control
              name='child_firstname'
              onChange={handleChange} 
              value={child.child_firstname}
              required
              type="text"
            />
            
          
          </Form.Group>

          <Form.Group as={Col} md="5" controlId="validationCustom01">
            <Form.Label>Achternaam</Form.Label>
            
            <Form.Control
              name='child_lastname'
              onChange={handleChange} 
              value={child.child_lastname}
              required
              type="text"
            />
            
          
          </Form.Group>

          </Row>
          <Row className="mb-3">

        
          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Label>Geboortedatum</Form.Label>
            
            <Form.Control
              name='child_date_of_birth'
              onChange={handleChange} 
              value={child.child_date_of_birth}
              required
              type="date"
            />
            
          
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Label>BSN</Form.Label>
            
            <Form.Control
              name='child_bsn'
              onChange={handleChange} 
              value={child.child_bsn}
              required
              type="number"
            />
            
          
          </Form.Group>
          </Row>

        
   

        <Button ref={childRef} className='btn-secondary' style={{ display: 'none' }} type="submit">Bevestig contactgegevens</Button>
        
      </Form>

      </>

    )
}

export default Child;
