import React from "react";

const FundTable = ({ filteredFund, category}) => {
return(


<div className="card card-full fund-intro">
<div className="card-body">


{filteredFund.fundmeta.filter(fundmetum => fundmetum.category.toString() === category).map(filteredData => {
    if (filteredData >= '0') return(
        <>
            <h4 className="mb-4">{filteredData.title}</h4>
            <p className="lead">{filteredData.data}</p>
        </>
) 
})}

<img  id="intro-graph" src={"https://admin.capler.nl/public_assets/" + filteredFund.logo} class="img-fluid" alt={filteredFund.name}></img>

</div>
</div>

)
}

export default FundTable;