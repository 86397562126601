import React  from 'react';
import {useState,useContext} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/UserContext'
import Topbar from '../../components/dashboard/Topbar';
import Sidebar from '../../components/dashboard/Sidebar';
import Navbar from './Navbar';
import { confirm } from "../../components/PopUp";
import { Link } from 'react-router-dom';
import axios from 'axios'

export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
  });


const Profiles = () => {


    const {user, text, profile, loggedInCheck} = useContext(UserContext);
    const [wait, setWait] = useState(false);
    const navigate = useNavigate();

    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
   
    const handleOnClick = async (pid) => {
        if (await confirm("Weet u zeker dat u dit profiel wilt verwijderen? Alle bijbehorende gegevens gaan verloren.")) {
            setWait(true);
            try{
                const loginToken = localStorage.getItem('loginToken');
                Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
                const {data} = await Axios.post('profile/delete.php',{
                  profile_id: pid
                }).then(response => response.data);
        
                
                
                navigate('/signup');
                await loggedInCheck();
                
                setWait(false);
                
                
          
            }
            catch(err){
                setWait(false);
                return {success:0, message:'Server Error!'};
            }
        } else {
         
        }
      }

      const [errMsg, setErrMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [modalData, setModalData] = useState({
        cor_phone: "",
        cor_street: "",
        cor_no: "",
        cor_zip: "",
        cor_city: "",
        cor_country: "",

      });
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setModalData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };
    
   
    const editProfile = async (e) => {
        e.preventDefault();
        setWait(true);
            try{
                const loginToken = localStorage.getItem('loginToken');
                Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
                const {data} = await Axios.post('profile/update.php',{
                  profile_id: modalData.profile_id,
                  cor_phone: modalData.cor_phone,
                  cor_street: modalData.cor_street,
                  cor_no: modalData.cor_no,
                  cor_zip: modalData.cor_zip,
                  cor_city: modalData.cor_city,
                  cor_country: modalData.cor_country
                }).then(response => response.data);
        
                
                
                
                await loggedInCheck();
                
                setWait(false);
                
                
          
            }
            catch(err){
                setWait(false);
                return {success:0, message:'Server Error!'};
            }
    }
    

    return (

    <>

    <div id="dashboard">

        <Topbar/>   
        <Sidebar/> 
        <div className="board settingsy">
            <Navbar/>
            <div className="card card-large settings">
                <div className="card-body">
                    <h6 className="text-left small mb-3 muted">{text.settings}</h6>
                    <h4 className="text-left">Profielen</h4>
                    <p className="settings-intro">Profielen waarmee u heeft belegd kunnen niet worden verwijderd en zijn beperkt te wijzigen.</p>
                    {profile.map((profile, index) => (
                     
                        <div className="file">
                            <div className="d-flex aic mw-60">
                                {profile.pers_bus === 'pers' ?
                                <h3><i className="bi muted bi-person"></i> </h3>
                                : 
                                <h3><i className="bi muted bi-briefcase"></i> </h3>
                                }
                                <div className="name">
                                <h6>{profile.profile_name}</h6>
                                {profile.pairing_code !== 'none' && profile.relation == 'JA' && profile.relation_status == 'open' (
                                <p className="small muted">Koppelcode: {profile.pairing_code}</p>
                                )}
                                </div>
                            </div>   
                        
                            <p></p>
                            {(profile.profile_ordered_amount === 0 && profile.profile_accepted_amount === 0) && (
                            <Link onClick={() => handleOnClick(profile.profile_id)} className="btn">Verwijder</Link>
                            )}
                            <Link onClick={() => {handleShow(); setModalData(profile);}} className="btn">Bewerk</Link>


                        <h6></h6>
                        
                        </div>

                    ))}

                </div>
            </div>
    </div>

   </div>

{modalData && (
<Modal size="lg" show={show} onHide={handleClose} pid={modalData.profile_id}>
<Modal.Header closeButton>
  <Modal.Title>{modalData.profile_name} bewerken</Modal.Title>
</Modal.Header>
<Form onSubmit={editProfile}>
<Modal.Body>
    
    
  
  <h5 className="mb-3">Telefoonnummer</h5>
  <Row className="mb-3">
    <Form.Group as={Col} xs={6} className="mb-3" controlId="exampleForm.ControlInput1">
      <Form.Label>Telefoonnummer</Form.Label>
      <Form.Control
        type="phone"
        name="cor_phone"
        value={modalData.cor_phone}
        onChange={handleInputChange}
      />
    </Form.Group>
   </Row>
   <h5 className="mb-3">Correspondentie</h5>
   <Row className="mb-3">
    <Form.Group as={Col} xs={8} controlId="exampleForm.ControlInput1">
      <Form.Label>Straatnaam</Form.Label>
      <Form.Control
        type="text"
        name="cor_street"
        value={modalData.cor_street}
        onChange={handleInputChange}
      />
    </Form.Group>
    <Form.Group as={Col} controlId="exampleForm.ControlInput1">
      <Form.Label>Huisnummer</Form.Label>
      <Form.Control
        type="text"
        name="cor_no"
        value={modalData.cor_no}
        onChange={handleInputChange}
      />
    </Form.Group>
  </Row>
  <Row className="mb-3">
    <Form.Group as={Col} xs={4} controlId="exampleForm.ControlInput1">
      <Form.Label>Postcode</Form.Label>
      <Form.Control
        type="text"
        name="cor_zip"
        value={modalData.cor_zip}
        onChange={handleInputChange}
      />
    </Form.Group>
    <Form.Group as={Col} controlId="exampleForm.ControlInput1">
      <Form.Label>Plaatsnaam</Form.Label>
      <Form.Control
        type="text"
        name="cor_city"
        value={modalData.cor_city}
        onChange={handleInputChange}
      />
    </Form.Group>
    
  </Row>
  
</Modal.Body>
<Modal.Footer>
  <Button className="btn btn-link" onClick={handleClose}>
    Annuleren
  </Button>
  <Button type="submit" className="btn btn-primary" onClick={handleClose}>
    Wijzigingen opslaan
  </Button>
</Modal.Footer>
</Form>
</Modal>
)}

</>



)
}

export default Profiles;