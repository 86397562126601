import React from "react";

export default function Redirecting(props) {
  return (
    
    <div className="spinner-container">
    <div className="spinner-subtext">
      <h5 className="text-center mt-3">U wordt doorverwezen...</h5>
      <p className="text-center muted mt-1">Sluit dit venster niet</p>
      </div>
  <div className="loading-spinner">
  <svg viewBox="0 0 55.2 39.7">
<defs>  
    <linearGradient id="logo-gradient" x1="50%" y1="0%" x2="50%" y2="100%" > 
        
        <stop offset="0%" stopColor="#6868dd">
            <animate attributeName="stop-color" values="#6868dd; #eeeeff; #6868dd" dur="3s" repeatCount="indefinite"></animate>
        </stop>

        <stop offset="100%" stopColor="#eeeeff">
            <animate attributeName="stop-color" values="#eeeeff; #6868dd; #eeeeff" dur="3s" repeatCount="indefinite"></animate>
        </stop>

    </linearGradient> 

</defs>

<g>

<g>
  <path fill="url('#logo-gradient')" d="M54.5,31.1L37.6,1.9c-1.5-2.6-5.2-2.6-6.7,0L20.1,20.5l9.4,16.3c1,1.8,2.9,2.9,5,2.9h14.9
    C53.9,39.7,56.7,34.9,54.5,31.1"/>
</g>


<g>
  <path fill="url('#logo-gradient')" d="M5.8,39.7L5.8,39.7c2.1,0,4-1.1,5-2.9l9.4-16.3l-3.3-5.7c-1.5-2.6-5.2-2.6-6.7,0L0.8,31.1
    C-1.4,34.9,1.3,39.7,5.8,39.7"/>
</g>

</g>
</svg>
  </div>
</div>
  );
}

