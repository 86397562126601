import React  from 'react';
import {useState} from 'react'
import axios from 'axios'




const MonthlyUpdate = (props) => {

    const [wait, setWait] = useState(false);

    const months = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];
    const dutchMonthName = months[props.month - 1];
   
    const handleDownload = async () => {
        try {
            const response = await axios.post('https://admin.capler.nl/filehandler/generate.php',
            { type: 'update', filename: 'Update '+dutchMonthName+' '+props.year, update_id: props.updateId }, { responseType: 'arraybuffer' });
            
           
            if (response.status === 200) {
                
                const fileBlob = new Blob([response.data], {
                    type: "application/pdf"
                });
                const fileUrl = URL.createObjectURL(fileBlob);
                window.open(fileUrl, '_blank');
            } else {
                console.error('Error downloading file:', response.statusText);
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };  


    return (

        
    
        <div className="file">
            <div className="d-flex aic mw-60">
                <h3 className="muted"><i className="bi bi-file-text"></i> </h3>
                <p>Update {dutchMonthName} {props.year} - Participatieklasse A</p>
                
            </div>   
         
            <p>{props.date}</p>
           
            <button onClick={handleDownload} className="btn">Download</button>
            
        </div>

        

    )
}

export default MonthlyUpdate;