import React, { useState } from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useContext} from 'react'
import {UserContext} from '../../context/UserContext'

export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
  });

const PassBack = () => {
    const {user, loggedInCheck} = useContext(UserContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [wait, setWait] = useState(false);
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const idType = queryParams.get('type');

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('file', selectedFile);
    formData.append('user_id', user.user_id);
    formData.append('file_type', 'passport_back');


    try{
            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const {data} = await Axios.post('user/passport_front.php', formData)
            .then(response => response.data);
            
            navigate('/invest');
            await loggedInCheck();
            
            setWait(false);
    }
    catch(err){
        setWait(false);
        return {success:0, message:'Server Error!'};
    }
  };

  return (
    <>
    {idType === 'child' ?
     <h3 className="text-left">Legitimatiebewijs minderjarige</h3>
  :  
  <h3 className="text-left">Achterkant paspoort</h3>
  }
   
    <p>Upload een foto of een scan van de achterkant van een geldig identiteitsbewijs (Paspoort of ID-kaart).</p>
    <Form className="extra-large" onSubmit={handleFileUpload}>
        <Row>
        <Form.Group as={Col} md="12" controlId="validationCustom01">
      <Form.Control type="file" onChange={handleFileChange} />
      </Form.Group>
      </Row>
      <button className="btn btn-primary mt-4 float-end" 
                type="submit"
                disabled={!selectedFile}
                >Opslaan en verder</button>
    </Form>
    </>
  );
};

export default PassBack;