import React  from 'react';
import Topbar from '../components/dashboard/Topbar';
import Sidebar from '../components/dashboard/Sidebar';
import First from './profiles/First';
import {useContext} from 'react';
import {UserContext} from '../context/UserContext'

const Profiles = () => {

    const {user, profile} = useContext(UserContext);
    

    return (

        

        <div id="dashboard">

            <Topbar/>   
            <Sidebar/> 
            <div className="board">

            
            <First/>  
          

           

            


            </div>  
            
        </div>

    )
}

export default Profiles;
