import React from "react";
import {useState, useContext, useEffect} from 'react';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';



const Contactdata = ({contact, setContact, submitRef, props}) => {

  const [validated, setValidated] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [zipValid, setZipValid] = useState(false);

  const checkboxChange = event => {
    if (event.target.checked) {
      setIsChecked(true);
      localStorage.setItem('corAddress', 'kvk');
    } else {
      setIsChecked(false);
      localStorage.setItem('corAddress', 'other');
    }
  };

  const persBus = localStorage.getItem('persBus');
  const relation = localStorage.getItem('relation');

  const handleChange = (e) => {
    setContact((prev) => ({
      ...prev,
      [e.target.name]: e.target.value.replace(/[^a-z0-9\s-]/gi, '')
    }));
    if (contact.cor_phone!= null && contact.cor_phone.length < 9) {
      setPhoneValid(true); 
      e. preventDefault();
    } else {
      setPhoneValid(false); 
    }
    if (contact.cor_zip!= null && contact.cor_zip.length < 3) {
      setZipValid(true); 
      e. preventDefault();
    } else {
      setZipValid(false); 
    }
    
  };

  

  const formOnChageHandler = (e) => {
    
   
   
  };

  

  const contactSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    
    setValidated(true);
  };

    return (

      <>

      <h4>Contactgegevens</h4>

      {relation === 'partner' && (
        <p>Indien u gezamenlijk belegt, kiest u slechts één contactpersoon. U zult beiden per mail op de hoogte worden gebracht.</p>
      )}

{relation === 'child' && (
        <p>Vul uw eigen contactgegevens in als wettelijke vertegenwoordiger van de minderjarige.</p>
      )}
      
        
        <Form className="large" noValidate onChange={formOnChageHandler} validated={validated} onSubmit={contactSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="5" controlId="validationCustom01">
            <Form.Label>Telefoonnummer</Form.Label>
            
            <Form.Control
              name='cor_phone'
              onChange={handleChange} 
              value={contact.cor_phone}
              required
              type="phone"
              onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
            />
            {phoneValid && 
            <div className="validationWarning">
      Voer een geldig telefoonnummer in
      </div>
      }
          
          </Form.Group>
          
          
        </Row>

      {/*
        {persBus === 'bus' && (

        <Row className="mb-3">
        <Form.Group as={Col} md="9" controlId="validationCustom01">
        <Form.Check // prettier-ignore
            id='useaddress'
            label='Gebruik bezoekadres zoals bekend bij de KvK.'
            value={isChecked}
            onChange={checkboxChange}

          />
          </Form.Group>
        </Row>

        )}

      */}

        {((persBus === 'bus' && !isChecked) || (persBus === 'pers')) && (
          <>
       
        <Row className="mb-3">
          <Form.Group as={Col} md="9" controlId="validationCustom01">
            <Form.Label>Straatnaam</Form.Label>
            
            <Form.Control
              name='cor_street'
              onChange={handleChange} 
              value={contact.cor_street}
              /* placeholder={isChecked && ("bla")}
              disabled={isChecked} */
              required
              type="text"
          
            />
            <Form.Control.Feedback></Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="validationCustom02">
            <Form.Label>Huisnummer</Form.Label>
            <Form.Control
              name='cor_no'
              onChange={handleChange} 
              value={contact.cor_no}
              required
              type="text"
            />
            <Form.Control.Feedback></Form.Control.Feedback>
          </Form.Group>
          
          
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="3" controlId="validationCustom03">
            <Form.Label>Postcode</Form.Label>
            <Form.Control 
            name='cor_zip'
              onChange={handleChange} 
              value={contact.cor_zip}
              type="text" 
              required 
             
              maxLength={10}
              onKeyDown={ (evt) => (evt.key === ' ') && evt.preventDefault() }
            />
            {zipValid && 
            <div className="validationWarning">
      Geen geldige postcode
      </div>
      }
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom04">
            <Form.Label>Stad</Form.Label>
            <Form.Control 
             name='cor_city'
             onChange={handleChange} 
             value={contact.cor_city}
            type="text" 
            required />
            <Form.Control.Feedback type="invalid">
              
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="5" controlId="validationCustom05">
            <Form.Label>Land</Form.Label>
            <Form.Control 
            name='cor_country'
             onChange={handleChange} 
             value={contact.cor_country}
            type="text" 
            required />
            <Form.Control.Feedback type="invalid">
              
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        </>

        )}
       
        <Form.Group className="mb-3">
        
        </Form.Group>

        <Button ref={submitRef} className='btn-secondary' style={{ display: 'none' }} type="submit">Bevestig contactgegevens</Button>
        
      </Form>

      </>

    )
}

export default Contactdata;
