import React  from 'react';
import {useState,useContext} from 'react';
import { Link } from 'react-router-dom';
import {UserContext} from '../context/UserContext';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Banner from './Banner';

const Login = () => {
    const {loginUser, wait, loggedInCheck} = useContext(UserContext);
    const [redirect, setRedirect] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [formData, setFormData] = useState({
        email:'',
        password:''
    });

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if(!Object.values(formData).every(val => val.trim() !== '')){
            setErrMsg('Vul alle vereiste velden in.');
            return;
        }

        const data = await loginUser(formData);
        if(data.success){
            e.target.reset();
            setRedirect('Redirecting...');
            await loggedInCheck();
            return;
        }
        setErrMsg(data.message);
    }

    return (
        <div className="splitter d-flex">
            <Banner/>
            
            <div className="split form">
                <div className="ext">
                    <h6>Nieuw bij Capler?</h6>
                    <Link className="link" to="/signup">Meld je eerst aan</Link>
                </div>
                <div className="auth">
                    <h3>Inloggen</h3>
                    <Form onSubmit={submitForm}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><i className="bi bi-envelope"></i></InputGroup.Text>
                        <Form.Control type="email" name="email" onChange={onChangeInput} placeholder="E-mail" id="email" value={formData.email} required />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><i className="bi bi-lock"></i></InputGroup.Text>
                        <Form.Control type="password" name="password" onChange={onChangeInput} placeholder="Wachtwoord" id="password" value={formData.password} required />
                    </InputGroup>
                    {errMsg && <div className="err-msg">{errMsg}</div>}
                        {redirect ? redirect : <button className="btn btn-primary" type="submit" disabled={wait}>Inloggen bij Capler</button>}
                    </Form>
                    
                   
                        <div className="bottom-link"><Link to="/forgot">Wachtwoord vergeten?</Link></div>
                </div>
            </div>
        
        </div>
    )
}

export default Login;