import React  from 'react';
import {useState, useContext, useEffect} from 'react'
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/UserContext'
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import axios from 'axios';

export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
  });


const ExtraForm = () => {

    const {user, text, loggedInCheck} = useContext(UserContext);
    const [wait, setWait] = useState(false);
    const navigate = useNavigate();
    const [Validated, setValidated] = useState(false);

    const [formData, setFormData] = useState({
        id_first_name:user.id_first_name,
        id_last_name:user.id_last_name,
        id_country:user.id_country,
        id_sex:user.id_sex,
        id_date_of_birth:user.id_date_of_birth_unf,
        id_bsn:user.id_bsn
    });

   
      
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        const form = e.currentTarget;
          if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
          }
          e.preventDefault();
          setValidated(false);
      
       
      
        const data = await Submit(formData);
        if(data.success){
            e.target.reset();
            
            
        }
        else if(!data.success && data.message){
            
        }
        
      }

    const Submit = async () => {

        setWait(true);
        try{
            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const {data} = await Axios.post('user/manual_id.php',{
              user_id: user.user_id,
              id_first_name:formData.id_first_name,
              id_last_name:formData.id_last_name,
              id_country:formData.id_country,
              id_sex:formData.id_sex,
              id_date_of_birth:formData.id_date_of_birth,
              id_bsn:formData.id_bsn,
              id_proof_method:formData.id_proof_method,
              id_upload_passport:formData.id_upload_passport,
              id_upload_banknote:formData.id_upload_banknote

            }).then(response => response.data);
            
            navigate('/invest');
            await loggedInCheck();
            
            setWait(false);
            
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Server Error!'};
        }
       
      };

      const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        })
    
        if (
            formData.id_first_name.length > 1 &&
            formData.id_last_name.length > 1 &&
            formData.id_country.length > 1 &&
           
            formData.id_bsn.length > 5 &&
            formData.id_upload_passport &&
            (formData.id_proof_method !== 'VIDEOCALL' ||
            (formData.id_proof_method !== 'BANKNOTE' &&
            formData.id_upload_banknote))
            ){
        setValidated(true);
        } else {
        setValidated(false);   
        }
    
      };

   

    return (

        <>
        <h3 className="text-left">Aanvullende gegevens nodig</h3>
                    <p>We konden uw gegevens helaas niet uitlezen. Nu zullen we overgaan op manuele identificatie.</p>
        
        <Form className="extra-large" noValidate onSubmit={handleSubmit}>


        <Form.Group controlId="formFile" className="mb-5">
        <h5>Paspoort uploaden</h5>
<Form.Text className="text-muted">
Upload een scan van een geldig paspoort.
</Form.Text>

<Form.Control type="file" name="id_upload_passport" onChange={handleChange}/>
</Form.Group>





       
<h5>Gewenste identificatiemethode</h5>

<Form.Text className="text-muted">
Selecteer de methode waarmee u uw identiteit wilt verifieren.
</Form.Text>


{['radio'].map((type) => (
<div key={`default-${type}`} className="mb-5 mt-3">
  <Form.Check // prettier-ignore
    type={type}
    id="VIDEOCALL"
    name="id_proof_method"
    label="Plan een videocall (5 min)"
    value="VIDEOCALL"
   
    onChange={handleChange}
    defaultChecked
  />

  <Form.Check
    type={type}
    label="Upload kopie bankafschrift"
    id="BANKNOTE"
    value="BANKNOTE"
    name="id_proof_method"
   
    onChange={handleChange} 
  />
</div>
))}

    {formData.id_proof_method === 'BANKNOTE' && ( 

        <Form.Group controlId="formFile" className="mb-5">
            <h5>Upload kopie bankafschrift</h5>
        <Form.Text className="text-muted">
        Upload een scan van een bankafschrift waarop uw persoonlijke gegevens staan vermeld.
        </Form.Text>

        <Form.Control type="file" onChange={handleChange} name="id_upload_banknote"/>
        </Form.Group>

    )}


<h5>Gegevens invoeren</h5>
<Form.Text className="text-muted mb-4">
Voer uw gegevens nauwkerig in, zoals vermeld op uw paspoort.
</Form.Text>

        <Row className="mb-3">
       
          <Form.Group as={Col} md="6" controlId="validationCustom01">
         
            <Form.Label>Voornamen</Form.Label> 
            <Form.Control
              name='id_first_name'
              onChange={handleChange}
              value={formData.id_first_name}
              required
              type="text"
            />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>Achternaam</Form.Label> 
            <Form.Control
              name='id_last_name'
              onChange={handleChange}
              value={formData.id_last_name}
              required
              type="text"
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="5" controlId="validationCustom01">
            <Form.Label>Nationaliteit</Form.Label> 
            <Form.Control
              name='id_country'
              onChange={handleChange}
              value={formData.id_country}
              required
              type="text"
            />
          </Form.Group>

          <Form.Group as={Col} md="3" controlId="validationCustom01">
            <Form.Label>Geslacht</Form.Label> 
            <Form.Select className="form-control" name='id_sex' defaultValue={formData.id_sex} aria-label="Default select example" onChange={handleChange}>
            <option disabled>Geslacht</option>
            <option value="F">Vrouw</option>
            <option value="M">Man</option>
            <option value="O">Anders</option>
          </Form.Select>
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Label>Geboortedatum</Form.Label> 
            <Form.Control
              name='id_date_of_birth'
              onChange={handleChange}
              value={formData.id_date_of_birth}
              required
              type="date"
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Label>BSN</Form.Label> 
            <Form.Control
              name='id_bsn'
              onChange={handleChange}
              value={formData.id_bsn}
              required
              type="text"
            />
          </Form.Group>

        </Row>

        

    <button
                className="btn btn-primary mt-4 float-end" 
                type="submit"
                disabled={!Validated}
                >
                Verder gaan
                </button>
        
      </Form>
      </>
        
    )
}

export default ExtraForm;


