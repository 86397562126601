import React  from 'react';

const DocsHeader = () => {

    return (

        
    
        <div className="file header">
            <div className="d-flex aic mw-60">
                
                <p className="strong">Document</p>
                
            </div>   
         
            <p className="strong">Datum</p>
            <button className="btn hide">Download</button>
        </div>

        

    )
}

export default DocsHeader;