import React  from 'react';
import {useState, useContext, useEffect} from 'react'
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/UserContext'
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import axios from 'axios';

export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
  });


const ExtraForm = () => {

    const {user, text, loggedInCheck} = useContext(UserContext);
    const [wait, setWait] = useState(false);
    const navigate = useNavigate();
    const [Validated, setValidated] = useState(false);

    const [formData, setFormData] = useState({
        id_first_name:user.id_first_name,
        id_last_name:user.id_last_name,
        id_country:user.id_country,
        id_sex:user.id_sex,
        id_date_of_birth:user.id_date_of_birth_unf,
        id_bsn:user.id_bsn
    });

   
      
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        const form = e.currentTarget;
          if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
          }
          e.preventDefault();
          setValidated(false);
      
       
      
        const data = await Submit(formData);
        
            e.target.reset();
            
            
        
        
      }

    const Submit = async (formData) => {

        setWait(true);
        try{
            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const {data} = await Axios.post('user/manual_id_temp.php',{
             
              user_id: user.user_id,
              id_first_name:formData.id_first_name,
              id_last_name:formData.id_last_name,
              id_country:formData.id_country,
              id_sex:formData.id_sex,
              id_date_of_birth:formData.id_date_of_birth,
              id_bsn:formData.id_bsn,
              id_proof_method:'banknote'
              

            }).then(response => response.data);
            
            navigate('/invest');
            await loggedInCheck();
            
            setWait(false);
            
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Server Error!'};
        }
       
      };

      const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        })
    
        if (
            formData.id_first_name != null &&
            formData.id_first_name.length > 1 &&
            formData.id_last_name != null &&
            formData.id_last_name.length > 1 &&
            formData.id_country != null &&
            formData.id_country.length > 1 &&
            formData.id_bsn != null &&
            formData.id_bsn.length > 5 
            ){
        setValidated(true);
        } else {
        setValidated(false);   
        }
    
      };

   

    return (

        <>
        <h3 className="text-left">Prettig kennismaken!</h3>
                    <p>Voordat u kunt beleggen, willen we uw identiteit verifiëren. Dit kan met een geldig paspoort of ID-kaart en een kopie van een bankafschrift.</p>
        
        <Form className="extra-large" noValidate onSubmit={handleSubmit}>


    


<h5>Gegevens invoeren</h5>
<Form.Text className="text-muted mb-4">
Voer uw gegevens nauwkeurig in, zoals vermeld op uw paspoort.
</Form.Text>

        <Row className="mb-3">
       
          <Form.Group as={Col} md="6" controlId="validationCustom01">
         
            <Form.Label>Voornamen</Form.Label> 
            <Form.Control
              name='id_first_name'
              onChange={handleChange}
              value={formData.id_first_name}
              required
              type="text"
            />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>Achternaam</Form.Label> 
            <Form.Control
              name='id_last_name'
              onChange={handleChange}
              value={formData.id_last_name}
              required
              type="text"
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="5" controlId="validationCustom01">
            <Form.Label>Nationaliteit</Form.Label> 
            <Form.Control
              name='id_country'
              onChange={handleChange}
              value={formData.id_country}
              required
              type="text"
            />
          </Form.Group>

          <Form.Group as={Col} md="3" controlId="validationCustom01">
            <Form.Label>Geslacht</Form.Label> 
            <Form.Select className="form-control" name='id_sex' defaultValue={formData.id_sex} aria-label="Default select example" onChange={handleChange}>
            <option disabled>Geslacht</option>
            <option value="F">Vrouw</option>
            <option value="M">Man</option>
            <option value="O">Anders</option>
          </Form.Select>
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Label>Geboortedatum</Form.Label> 
            <Form.Control
              name='id_date_of_birth'
              onChange={handleChange}
              value={formData.id_date_of_birth}
              required
              type="date"
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Label>BSN</Form.Label> 
            <Form.Control
              name='id_bsn'
              onChange={handleChange}
              value={formData.id_bsn}
              required
              type="text"
            />
          </Form.Group>

        </Row>
        <div className="card-btn d-flex">

        <Link to="/identification">
      <Button type="submit" variant="link">
        Vorige
      </Button>
    </Link>
        

    <button
                className="btn btn-primary float-end" 
                type="submit"
                disabled={!Validated}
                >
                Opslaan en verder
                </button>
                </div>
        
      </Form>
      </>
        
    )
}

export default ExtraForm;


