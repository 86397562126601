import React  from 'react';
import {useContext} from 'react'
import {UserContext} from '../../context/UserContext'
import Onboarding from './dashboard-items/Onboarding';
import Overview from './dashboard-items/Overview';
import UpdatePreview from './dashboard-items/UpdatePreview';
import InboxItem from './dashboard-items/InboxItem';
import OpenActions from './dashboard-items/OpenActions';




const Board = () => {

    const {user, profile} = useContext(UserContext);
    let isOpenActionsRendered = false;


return (
    
    <div className="board preview">

  
        {user.has_investments === 'no'
        ? <Onboarding/>
        : <Overview/>
        }
      

        <InboxItem/> 
        {/*
        {profile.map((profile, index) => {
    if ((profile.relation_status === 'open' || profile.relation_status === 'invited') && !isOpenActionsRendered) {
        isOpenActionsRendered = true;
        return <OpenActions key={index} />;
    }
    return null;
})}
        */}

        {user.participated && user.participated === 'yes' && (
            <UpdatePreview/>
        )}

        
       



    </div>
)


}

export default Board;


