import Topbar from '../components/dashboard/Topbar';
import Sidebar from '../components/dashboard/Sidebar';
import React from "react";
import {useState,useContext,useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../context/UserContext'
import Questionnaire from './invest/Profile';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Profile from './invest/Profile';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Investment from './invest/Investment';
import Signature from './invest/Signature';
import PaymentResponse from './invest/PaymentResponse';
import Redirecting from '../components/Redirecting';
import axios from 'axios'
export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
  });






const Invest = () => {

    const {user, loggedInCheck} = useContext(UserContext);
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [wait, setWait] = useState(false);
    const [url, setUrl] = useState([]);
    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    /* Set current tab based on local storage */

    const localProgress = localStorage.getItem('setProgress');

    const [currentTab, setCurrentTab] = useState(localProgress || 'profile');

    const [progressTab, setProgressTab] = useState();

    /* Define selected profile */
    
    const selectedProfile = localStorage.getItem('selectedProfile');

    const [enableState, setEnableState] = useState({selectedProfile})
    
    useEffect(() => {
        if (localStorage.getItem('selectedProfile')) {
            setEnableState(!{selectedProfile});
            } else {
                setEnableState({selectedProfile});
            }
      }, [selectedProfile]);

      const onProfileChange=(newValue)=>{
        localStorage.setItem('selectedProfile', newValue);
        localStorage.setItem('selectedMethod', 'regular');
        if ({selectedProfile}) {
        setEnableState(!{selectedProfile});
        } else {
        setEnableState({selectedProfile}); 
        }
      }

    /* Define selected method */
    
    const selectedMethod = localStorage.getItem('selectedMethod');

    

    const [enableMethod, setEnableMethod] = useState({selectedMethod})
    
    useEffect(() => {
        if (localStorage.getItem('selectedMethod')) {
          setEnableMethod(!{selectedMethod});
            } else {
              setEnableMethod({selectedMethod});
            }
      }, [selectedMethod]);

      const onMethodChange=(newValue)=>{
        localStorage.setItem('selectedMethod', newValue);
        
        if ({selectedMethod}) {
          setEnableMethod(!{selectedMethod});
        } else {
          setEnableMethod({selectedMethod}); 
        }
      }


    /* Define set amount */

    const setAmount = localStorage.getItem('setAmount');

    const minSetAmount = localStorage.getItem('minSetAmount');

    const [typeAmount, setTypeAmount] = useState('')

    const [minimumAmount, setMinimumAmount] = useState(true)

    

    const onAmountChange=(newAmount, minAmount)=>{
      
        const formattedAmount = parseFloat(newAmount.replace(/[.]/g, ''));
      
        localStorage.setItem('setAmount', formattedAmount);
        localStorage.setItem('minSetAmount', minAmount);
        
        setTypeAmount(newAmount);
        if (formattedAmount != null && formattedAmount >= minAmount && setAmount > 0) {
            setMinimumAmount(false)
        } else {
            setMinimumAmount(true)
        }

              // Add the following function to pass as a prop to the child component
      
      
      }
      const handleRecurringChange = (value) => {
        // Call setMinimumAmount(true) when handleRecurring is triggered in the child
        setMinimumAmount(true);
      }


      const handleClose = () => {
        localStorage.removeItem('setProgress');
        return '/';
      }


      /* see if all control boxes are checked*/

      const [allChecked, setAllChecked] = useState(false);

      const [profileIsChecked, setProfileIsChecked] = useState(false);

  const handleProfile = () => {
    setProfileIsChecked(!profileIsChecked);
  };

  const [methodIsChecked, setMethodIsChecked] = useState(false);

  const handleMethod = () => {
    setMethodIsChecked(!methodIsChecked);
  };

  const [amountIsChecked, setAmountIsChecked] = useState(false);

  const handleAmount = () => {
    setAmountIsChecked(!amountIsChecked);
  };

  const [contractIsChecked, setContractIsChecked] = useState(false);

  const handleContract = () => {
    setContractIsChecked(!contractIsChecked);
  };

  useEffect(() => {
    if (contractIsChecked && amountIsChecked && profileIsChecked && methodIsChecked) {
        setAllChecked(false)
    } else {
        setAllChecked(true)
    }
  });


    /* Create order */

    const handleCreateOrder = async (e) => {
        e.preventDefault();
        setWait(true);
        const form = e.currentTarget;
          if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
          }
          e.preventDefault();
          setValidated(true);
      
        if(!Object.values(formData).every(val => val.trim() !== '')){
            
            return;
        }
      
        const data = await createOrder(formData);
        if(data.success){
            e.target.reset();
            
            
        }
        else if(!data.success && data.message){
            
        }
        
      }

     

     
      
      const createOrder = async () => {
    
        
        try{
            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            setIsLoading(true);
            const {data} = await Axios.post('investment/create.php',{
              user_id: user.user_id,
              profile_id: localStorage.getItem('selectedProfile'),
              amount: localStorage.getItem('brutAmount'),
              pension: localStorage.getItem('selectedMethod'),
              recurring: localStorage.getItem('selectedRecurring'),
              
    
            }).then(response => response.data).then((d) => {
              setUrl(d);
              window.location.replace(d.Signers[0].SignUrl); // Move the redirect code here
            });
        
          localStorage.removeItem('setAmount');
          localStorage.removeItem('minSetAmount');
          localStorage.removeItem('selectedMethod');
          localStorage.removeItem('selectedProfile');
          localStorage.removeItem('selectedRecurring');
          localStorage.removeItem('setProgress');
          
        
            setIsLoading(false);
            
           // setErrMsg(false);

           
            
      
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Server Error!'};
        }
      
        
      }

      if (isLoading) {
        return <Redirecting />;
      }
    
      /* Set tabs and save */

    const toIdentification = (eventKey) => {
    setCurrentTab("identification");
    localStorage.setItem('setProgress', 'identification');
    };

    const toProfile = (eventKey) => {
      setCurrentTab("profile");
      localStorage.setItem('setProgress', 'profile');
      setProgressTab(0);
    };

  const toInvestment = (eventKey) => {
    setCurrentTab("investment");
    localStorage.setItem('setProgress', 'investment');
    localStorage.setItem('selectedRecurring', 'no');
    setProgressTab(1);
  };

  const toSign = (eventKey) => {
    setCurrentTab("sign");
    localStorage.setItem('setProgress', 'sign');
    setProgressTab(2);
  };

  const toTransaction = (eventKey) => {
    setCurrentTab("transaction");
    localStorage.setItem('setProgress', 'transaction');
    setProgressTab(3);
  };

  

    return (

        

        <div id="dashboard">

            <Topbar/>   
            <Sidebar/> 
    
            <div className="board">

            <Tabs
                defaultActiveKey="profile"
                activeKey={currentTab}
                id=""
                className="min-w-100 invest-flow"
                onSelect={(key) => setCurrentTab(key)}
                >
                    
                 <Tab eventKey="profile" title={<span>{progressTab > 0 && (<i className="bi navcheck bi-check-circle-fill"></i>)} Profiel</span>} disabled>
                    <div className="card card-full">
                        <div className="card-body">
                            <Profile onProfileChange={onProfileChange} /> 
                            <div className="card-btn d-flex">
                            
                            <button
                            onClick={toInvestment}
                            className="btn btn-primary" 
                            disabled={enableState}
                            >
                            ga verder met de belegging
                            </button>
                            </div>

                        </div>
                    </div>
                </Tab>
               
                <Tab eventKey="investment" title={<span>{progressTab > 1 && (<i className="bi navcheck bi-check-circle-fill"></i>)} Belegging</span>} disabled>
                <div className="card card-full">
                        <div className="card-body">
                        <Investment onRecurringChange={handleRecurringChange} onAmountChange={onAmountChange} onMethodChange={onMethodChange} /> 
                            <div className="card-btn d-flex">
                            <button
                            onClick={toProfile}
                            className="btn btn-link"
                            
                            >
                            Vorige
                            </button>
                            <button
                            onClick={toSign}
                            className="btn btn-primary"
                            disabled={minimumAmount}
                            >
                            Naar ondertekenen
                            </button>
                            </div>

                        </div>
                    </div>
                
                </Tab>
                <Tab eventKey="sign" title={<span>{progressTab > 2 && (<i className="bi navcheck bi-check-circle-fill"></i>)} Controle</span>} disabled>
                <div className="card card-full">
                        <div className="card-body">
                        <Signature 
                        profileIsChecked={profileIsChecked} 
                        handleProfile={handleProfile}
                        handleMethod={handleMethod}
                        methodIsChecked={methodIsChecked}
                        amountIsChecked={amountIsChecked} 
                        handleAmount={handleAmount} 
                        contractIsChecked={contractIsChecked} 
                        handleContract={handleContract}  /> 
                         {allChecked && (
                            <Alert key='warning' variant='warning' className=" d-block fw mt-4">
                              Let op! Selecteer alle bovenstaande checkboxes om over te kunnen gaan tot ondertekening. Mocht er iets niet kloppen, kunt u uw voorkeuren wijzigen bij de voorgaande stappen. Beleggen volgens aanvullende voorwaarden lijfrente kan uitsluitend met een persoonlijk profiel. Lees hier meer over <Alert.Link target="_blank" href="https://capler.nl/files/documents.php?doc=annuity">aanvullende voorwaarden lijfrente.</Alert.Link> 
                            </Alert>
                            )}
                            <div className="card-btn d-flex">
                            <button
                            onClick={toInvestment}
                            className="btn btn-link"
                            
                            >
                            Vorige
                            </button>
                            <Form className="large noinput" onSubmit={handleCreateOrder}>
                           
                            <button
                            className="btn btn-primary" 
                            type="submit"
                            disabled={allChecked}
                            >
                            Verder naar ondertekening
                            </button>
                            </Form>

                           

                            </div>

                        </div>
                    </div>
                </Tab>
                <Tab eventKey="transaction" title="Transactie" disabled>
                <div className="card card-full">
                        <div className="card-body">
                        <PaymentResponse />
                        <div className="card-btn d-flex">
                            
                            <Form className="large noinput" onSubmit={handleClose}>
             
                            <button
                            className="btn btn-primary" 
                            type="submit"
                            
                            >
                            Terug naar mijn dashboard
                            </button>
                            </Form>
                            </div>
                        </div>
                        </div>
                </Tab>
            </Tabs>

            
            </div>
            
            
        </div>

    )
}

export default Invest;
