import React  from 'react';
import {useState, useContext, useEffect, useRef} from 'react'
import Topbar from '../../components/dashboard/Topbar';
import Sidebar from '../../components/dashboard/Sidebar';
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/UserContext'
import Redirecting from '../../components/Redirecting';
import axios from 'axios';
import { Onfido } from 'onfido-sdk-ui'

export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
  });

  const OnfidoWrapper = () => {
    const { user } = useContext(UserContext);
    const [wait, setWait] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const hasFetchedData = useRef(false);

    useEffect(() => {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const loginToken = localStorage.getItem('loginToken');
          Axios.defaults.headers.common['Authorization'] = 'Bearer ' + loginToken;
          const { data } = await Axios.post('workOnfido.php', {
            user_id: user.user_id
          });
          window.location.replace(data.url); // Move the redirect code here
        } catch (err) {
          setWait(false);
          console.error('Error:', err);
          return { success: 0, message: 'Server Error!' };
        } finally {
          setIsLoading(false);
        }
      };
    
      if (!hasFetchedData.current) {
        fetchData();
        hasFetchedData.current = true; // Ensure it only runs once
      }
    }, []);

    if (isLoading) {
      return <Redirecting />;
    }
   
  
    return <Redirecting />;
  };
  
  export default OnfidoWrapper;
  

