import React  from 'react';
import {useContext, useState} from 'react'
import {UserContext} from '../../context/UserContext'
import {useNavigate} from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Topbar from '../../components/dashboard/Topbar';
import Sidebar from '../../components/dashboard/Sidebar';
import Navbar from './Navbar';
import { confirm } from "../../components/PopUp";
import { Link } from 'react-router-dom';
import axios from 'axios'

export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
  });

const Account = () => {

    const navigate = useNavigate();
    const {user, text, updateUser, loggedInCheck} = useContext(UserContext);
    const [errMsg, setErrMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [wait, setWait] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        first_name:user.first_name,
        last_name:user.last_name,
        email:user.email
    });
    const [mailData, setMailData] = useState({
        email:user.email,
        user_id:user.user_id
    });

    

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value.replace(/[^a-z\s-]/gi, '')
        })
    }

    const onChangeMail = (e) => {
        setMailData({
            ...mailData,
            [e.target.name]:e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if(!Object.values(formData).every(val => val.trim() !== '')){
            setSuccessMsg(false);
            setErrMsg('Please Fill in all Required Fields!');
            return;
        }

        const data = await updateUser(formData);
        if(data.success){
            e.target.reset();
            setSuccessMsg('You have successfully updated.');
            setErrMsg(false);
        }
        else if(!data.success && data.message){
            setSuccessMsg(false);
            setErrMsg(data.message);
        }
        
    }

    /* Update mail */

    const submitMail = async (e) => {
      
        e.preventDefault();
       
        const form = e.currentTarget;
          if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
          }
          e.preventDefault();
          
          
      
        if(!Object.values(formData).every(val => val.trim() !== '')){
            
            return;
        }
      
        const data = await pushMail(mailData);
      
        if(data.success){
            e.target.reset();
            
            
        }
        else if(!data.success && data.message){
            
        }
        
        
      }

    const pushMail = async (e) => {
        
        setLoading(true);
        setWait(true);
        try{
            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const {data} = await Axios.post('user/update_email.php',{
                user_id: mailData.user_id,
                email: mailData.email
            });
            if(data.success){
                navigate('/settings');
                await loggedInCheck();
                setWait(false);
              }
              else if(!data.success && data.message){
                  setSuccessMsg(false);
                  setErrMsg(data.message);
              }
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Server Error!'};
        }

    }

    /* remove account */

    const handleOnClick = async (uid) => {
        if (await confirm("Weet u zeker dat u uw account wilt verwijderen? Alle bijbehorende gegevens gaan verloren.")) {
            setWait(true);
            try{
                const loginToken = localStorage.getItem('loginToken');
                Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
                const {data} = await Axios.post('user/delete.php',{
                  user_id: uid
                }).then(response => response.data);
        
                
                
                navigate('/settings');
                await loggedInCheck();
                
                setWait(false);
                
                
          
            }
            catch(err){
                setWait(false);
                return {success:0, message:'Server Error!'};
            }
        } else {
         
        }
      }
    

    return (

        <div id="dashboard">

        <Topbar/>   
        <Sidebar/> 
        <div className="board settingsy">
        <Navbar/>
        <div className="card card-large settings">
            <div className="card-body">
    <h6 className="text-left small mb-3 muted">{text.settings}</h6>
    <h4 className="text-left">Account</h4>
    <p className="">Hier kunt u de weergavenaam van uw account wijzigen. Zo spreken wij u aan in e-mails.</p>
    <Form onSubmit={submitForm} className="large container pl-0 ml-0">
        <Row>
            <Col>
                <Form.Control className="form-control" type="text" onChange={onChangeInput} value={formData.first_name} id="first_name" name="first_name" required/>
            </Col>
            <Col>
                <Form.Control className="form-control" type="text" onChange={onChangeInput} value={formData.last_name} id="last_name" name="last_name" required/>
            </Col>
            
        </Row>
        <Form.Control type="email" value={formData.email} id="email" name="email" hidden required/>
        <input className="btn btn-sm" value={text.save_changes} type="submit"/>
        

            
    </Form>

    <div className="breaker"></div>
    <h5 className="text-left mb-3">Wijzig uw e-mailadres</h5>
    <p className="">Let op! Als u uw e-mail wijzigt, kunt u niet meer inloggen totdat uw nieuwe adres is geverifieerd.</p>
    
    <Form onSubmit={submitMail} className="large container pl-0 ml-0">
        <Row>
        <Col>
                <Form.Control 
                textContentType='emailAddress'
                keyboardType='email-address'
                autoCapitalize='none'
                autoCorrect={false}
                autoCompleteType='email'
                className="form-control w-26" 
                type="email" 
                onChange={onChangeMail} 
                value={mailData.email} 
                id="email" 
                name="email" 
                required/>
                {errMsg && <div className="err-msg">{errMsg}</div>}
                </Col>
            
        </Row>
        
        <input className="btn btn-sm" value={text.save_changes} type="submit"/>
        
        
            
    </Form>

            
    {user.has_investments === 'no' && (
<>
    <div className="breaker"></div>
    <h5 className="text-left mb-3">Account opheffen</h5>
    <p className="">Als u eenmaal beleggingen heeft gedaan, kunt u uw account niet opheffen.</p>
    <Form></Form>
<Link onClick={() => handleOnClick(user.user_id)} className="btn btn-sm">Account opheffen</Link>
  
    </>
    )}
    {/* 
    <div className="breaker"></div>
    <h5 className="text-left mb-3">Stel een nieuw wachtwoord in</h5>
    <p className="">Wij sturen u een e-mail met daarin een link, waarmee u uw nieuwe wachtwoord in kan stellen.</p>
    
    <form>
    
    <input className="btn btn-sm" type="submit" value="Verstuur wachtwoord herstel link" />
    </form>


    
    {successMsg && <div className="success-msg">{successMsg}</div>}
    {errMsg && <div className="err-msg">{errMsg}</div>}
    <h4>{text.password}</h4>
    <p>{text.send_pass_reset_to} {user.email}</p>
    
    */}
   </div>
   </div>
   </div>
   </div>
)
}

export default Account;