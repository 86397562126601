import React  from 'react';
import {useContext, useState} from 'react'
import { useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/UserContext'
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import {Button} from 'react-bootstrap';
import logo from '../../img/capler-logo.svg';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Sidebar from './Sidebar';


const Topbar = () => {

    const {user, text, logout} = useContext(UserContext);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function About() {
        const navigate = useNavigate();
        const goBack = () => {
          navigate(-1);
        }
        return (
          <button onClick={goBack}>Back</button>	
          
          );
      }

    return (
       
        <nav className="navbar topbar">
            <div className="container-fluid">
            <div className="top-left">
                <Button variant="link show-sm-medium" onClick={handleShow}>
                <h5><i className="bi bi-list"></i></h5>
                </Button>
                <Link className="navbar-brand" to='/'>
                    <img className="logo" src={logo} alt="Logo" />
                </Link>
            </div>

                <div className='controls'>
                {(window.location.pathname !== "/invest") ?
                <>
               
                    
                    {!user.id_first_name ?
                    <Link className="hide-small" to='/invest'><Button variant="secondary">Start uw eerste belegging</Button></Link>
                    : user.has_investments === 'no' ?
                    <Link className="hide-small" to='/invest'><Button variant="secondary">Vervolg uw eerste belegging</Button></Link>
                    :
                    <Link className="hide-small" to='/invest'><Button variant="secondary">Start een nieuwe belegging</Button></Link>
                    }
                     </>
                     :
                     <></>
                    }
               
                

                <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                {user.first_name.charAt(0)}.
                {user.last_name.charAt(0)}.
                <i class="bi bi-three-dots"></i>
                </Dropdown.Toggle>

                

                <Dropdown.Menu>
                <Dropdown.Item as={Link} to={'/inbox'}><i className="bi bi-inbox"></i>{text.inbox}</Dropdown.Item>
                    <Dropdown.Item as={Link} to={'/settings'}><i className="bi bi-gear"></i>{text.settings}</Dropdown.Item>
                    <Dropdown.Item onClick={logout}><i className="bi bi-box-arrow-right"></i>{text.logout}</Dropdown.Item>
                </Dropdown.Menu>
                </Dropdown>
                </div>

                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                    <Link className="navbar-brand" to='/'>
                        <img className="logo" src={logo} alt="Logo" />
                    </Link>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Sidebar />
                    </Offcanvas.Body>
                </Offcanvas>
                
                
            </div>
        </nav>

        
    )
}

export default Topbar;


