import React  from 'react';
import {useContext} from 'react'
import {UserContext} from '../../context/UserContext'
import Topbar from '../../components/dashboard/Topbar';
import Sidebar from '../../components/dashboard/Sidebar';
import Breadcrumbs from './Breadcrumbs';
import DocsHeader from './DocsHeader';
import StaticPdf from '../../documents/StaticPdf';



const Contracts = () => {

    const {documents} = useContext(UserContext);


    return (
        
        <div id="dashboard">

            <Topbar/>   
            <Sidebar/> 

            <div className="board">

                <Breadcrumbs/>

                <div className="card card-full">
                    <div className="card-body">

                    <DocsHeader/>

                    {[...documents].reverse().map((document, index) => (
                        <>
                       {document.owner === 'investment' && (

                       <StaticPdf document={document} />

                       )}
                       </>
                        ))}
                        

                     
            
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Contracts;