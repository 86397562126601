import React  from 'react';
import {useContext} from 'react'
import {UserContext} from '../../context/UserContext'
import Topbar from '../../components/dashboard/Topbar';
import Sidebar from '../../components/dashboard/Sidebar';
import Breadcrumbs from './Breadcrumbs';
import DocsHeader from './DocsHeader';
import StaticPdf from '../../documents/StaticPdf';



const FundDocuments = () => {

    const {documents, user} = useContext(UserContext);


    return (
        
        <div id="dashboard">

            <Topbar/>   
            <Sidebar/> 

            <div className="board">
                {user.has_investments === 'yes' && (
                <Breadcrumbs/>
                )}
                <div className="card card-full">
                    <div className="card-body">

                    <DocsHeader/>

                    {documents.map((document, index) => (
                        <>
                       {document.owner === 'fund' && (

                       <StaticPdf document={document} />

                       )}
                       </>
                        ))}
                        

                     
            
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FundDocuments;