import React  from 'react';
import { Link } from 'react-router-dom';
import {useContext} from 'react'
import {UserContext} from '../../context/UserContext'
import { Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    events: null,
    onClick: null,
    plugins: {
        legend: {
            display: false, // Change to true to display the legend
        },
        tooltip: {
            enabled: false, // Change to true to display the legend
        },
    }
};

const Basket = ({ filteredFund }) => {

    const {funds, text, user} = useContext(UserContext);  

    const labels = funds[0].subfunds.map((subfund) => subfund.name);
    const amounts = funds[0].subfunds.map((subfund) => subfund.amount);

    const colors = [
        '#6868dd',
      
        '#c6cafa',
        '#9190dc',
        '#f2ba4e',
        '#1b232b',
       
        
    ]

    const data = {
        labels: labels,
    
        datasets: [
          {
            label: 'Percentage',
            data: amounts,
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 1,
          },
        ],
      };

    return (


  <div className="card card-full">
    <div className="card-body">

        <div class="card-top mb-4">
            <div class="left">
                <h4 class="text-left">Huidige portefeuille</h4>
                <p class="">Dit is een momentopname. De exacte verdeling van de portefeuille verandert met verloop van tijd. Klik op een fonds voor meer informatie.</p>
            </div>
        
        </div>


<h4></h4>


        
        <div className="chart-container">



            {user.has_investments === 'yes' && (

            <div className="doughnut-container">
                <Doughnut data={data} options={options} updateMode={"none"} />
            </div>

            )}
            <div className="legend-container orders">
               
                {funds[0].subfunds.map((subfund, index) => (
                   
                   <Link className="order" to={"/funds/" + filteredFund.public_id + "/" + subfund.public_id }>
                      
                            <div class="tt_icon small" style={{ backgroundColor: colors[index] }} >
                            
                            </div>
                            <div class="left">
                                <p>{subfund.name}</p>
                                
                            </div>
                            <div class="right">
                                <div className="btn btn-small"><i class="bi bi-three-dots"></i></div>
                            </div>
                       
                    </Link>

                ))}
            </div>

         </div>

         </div>



    </div>
  

  

    )
}

export default Basket;


