import React  from 'react';
import {useContext, useEffect, useState} from 'react'
import {UserContext} from '../../context/UserContext'
import { Link } from 'react-router-dom';



const Profile = (props) => {

    const {user, profile} = useContext(UserContext);
    const [checked, setChecked] = useState(false);


    const selectedProfile = localStorage.getItem('selectedProfile');

    useEffect(() => {
    
    if (!selectedProfile) {
            localStorage.setItem('selectedProfile', profile[0].profile_id);
            localStorage.setItem('selectedMethod', 'regular');
            setChecked(true);
        } 
        
    }, [profile]);


    
 
    
    
    

    return (
        
        

                <div className="mw-100">

               

                <div className="card-top">
                    <h4 className="text-left">Selecteer een profiel</h4>
                    <div className="gap-s">
                    {/*<Link to='/profiles/' className="btn hide-phone">+ Voeg een profiel toe </Link>*/}
                    <Link to='/settings/profiles/' className="btn hide-phone">Beheer profielen</Link>
                    </div>
                </div>
                <p>Selecteer een profiel. Je kan als natuurlijk persoon of vanuit één of meerdere bedrijven beleggen.</p>
               
                <div className="profiles">
                {selectedProfile && ( <>
                {profile.map((profile, index) => (
                 <label>
                    <input 
                    type="radio" 
                    name="profile" 
                    onChange={(e)=>{props.onProfileChange(e.target.value)}} 
                    value={profile.profile_id} 
                    className="card-input-element" 
                    defaultChecked={profile.profile_id === selectedProfile}
                    disabled={!profile.profile_name}
                    />
                    <div className="card">
                        <div className="card-body">
                            <h5>{profile.profile_name}</h5>
                            {profile.pers_bus === 'pers' && profile.relation == 'PC' ? 
                    <p>Privé (minderjarige)</p>
                    : profile.pers_bus === 'pers' && profile.relation == 'JA' ?
                    <p>Privé (gezamenlijk)</p>
                    : profile.pers_bus === 'pers' ?
                    <p>Persoonlijk</p>
                    : 
                    <p>Zakelijk</p>
                     }
                        <div className="checks">
                        {user.id_progress !== 'COMPLETED' ? 
                        <p><i className="bi bi-x"></i> Identificatie: Niet geverifieerd</p>
                        : (user.id_doc_status !== 'APPROVED' || user.id_proof_status !== 'APPROVED') ? 
                        <p><i className="bi bi-check"></i> Identificatie: In behandeling</p>
                        :
                        <p><i className="bi bi-check-all"></i> Identificatie: Compleet</p>
                        }
                        {!profile.profile_name ? 
                        <p><i className="bi bi-x"></i> Gegevens: Incompleet</p>
                        : 
                        <p><i className="bi bi-check-all"></i> Gegevens: Compleet</p>
                        }
                        
                        {profile.profile_accepted_amount > 0 ? 
                        <p><i className="bi bi bi-check-all"></i> Participatie: Ja</p>
                        : 
                        <p><i className="bi bi-x"></i> Participatie: Geen</p>
                        }
                        </div>
                       
                         
                         <i className="bi bi-check-circle-fill"></i>
                         <i className="bi bi-circle-fill"></i>
                         
                        </div>
                    </div>


                   


                    </label>
            ))}
            </>)}
            <Link className="h-100 card label" to='/profiles/'>
         
                    
              
                        <div className="card-body">
                            <h5>+ Nieuw</h5>
                            <p>Maak een nieuw profiel aan</p>
                           <p className="mt-auto"></p>
                        </div>
                  


                   

                    </Link>

            </div>
            
           
            


               

                </div>
               
        
    )
}

export default Profile;


