import React  from 'react';
import {useContext, useState} from 'react'
import {UserContext} from '../../context/UserContext'
import {useNavigate} from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Topbar from '../../components/dashboard/Topbar';
import Sidebar from '../../components/dashboard/Sidebar';
import Navbar from './Navbar';
import axios from 'axios'

export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
  });

const Filesharing = () => {

    const {user, loggedInCheck, text} = useContext(UserContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [successMsg, setSuccessMsg] = useState(false);
  const [wait, setWait] = useState(false);
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('file', selectedFile);
    formData.append('user_id', user.user_id);
    formData.append('file_type', 'passport_front');


    try{
            const loginToken = localStorage.getItem('loginToken');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
            const {data} = await Axios.post('user/add_document.php', formData)
            .then(response => response.data);
            setSuccessMsg('Het delen is gelukt. We hebben uw document ontvangen.');
            setWait(false);
              // Clear the selected file after successful upload
        setSelectedFile(null);
        
        // Reset the file input field
        document.getElementById('file-input').value = '';
    }
    catch(err){
        setWait(false);
        return {success:0, message:'Server Error!'};
    }
  };

    return (

        <div id="dashboard">

        <Topbar/>   
        <Sidebar/> 
        <div className="board settingsy">
        <Navbar/>
        <div className="card card-large settings">
            <div className="card-body">
    <h6 className="text-left small mb-3 muted">{text.settings}</h6>
    <h4 className="text-left">Documenten</h4>
    <p className="mb-3">Mochten wij u verzocht hebben documenten met ons te delen, kunt u deze hier veilig uploaden.</p>
    <Form className="extra-large mt-5" onSubmit={handleFileUpload}>
        <Row>
        <Form.Group as={Col} md="12" controlId="validationCustom01">
      <Form.Control type="file" id="file-input" onChange={handleFileChange} />
      </Form.Group>
      </Row>
      <button className="btn btn-sm" 
                type="submit"
                disabled={!selectedFile}
                >Uploaden</button>
                {successMsg && <div className="success-msg mb-0">{successMsg}</div>}
    </Form>

   </div>
   </div>
   </div>
   </div>
)
}

export default Filesharing;