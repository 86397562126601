import {useState,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import Topbar from '../components/dashboard/Topbar';
import Sidebar from '../components/dashboard/Sidebar';
import Board from '../components/dashboard/Board';
import React from "react";
import { NavLink } from 'react-router-dom';



const Funds = () => {

const {funds, text} = useContext(UserContext);  
const numDescending = [...funds].sort((a, b) => b.order_date - a.order_date); 
const [fund, setFunds] = useState([]);

function loadFund(e) {
    const item = e.target.value;
    setFunds([...funds]);
  }

    return (

        

        <div id="dashboard">

            <Topbar/>   
            <Sidebar/> 
            <div className="board">
                
              {numDescending.map((funds, idx) => { 
              
              return(

              
                            
                <NavLink className="card" key={idx} to={funds.public_id} onClick={loadFund}>
                   
                        <div className="card-body">
                            <h4 className="text-left">{funds.name}</h4>
                        </div>
                  
                </NavLink>
                )
              
                        })}
                    
                    
                 
            </div>  
            
            
        </div>

    )
}

export default Funds;
