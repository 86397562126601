import React  from 'react';
import Topbar from '../../components/dashboard/Topbar';
import Sidebar from '../../components/dashboard/Sidebar';
import Redirecting from '../../components/Redirecting';
import {useState,useContext,useEffect} from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import {UserContext} from '../../context/UserContext';
import { Link } from 'react-router-dom';
export const Axios = axios.create({
  baseURL: 'https://admin.capler.nl/',
});


const PaymentResponse = (props) => {

const queryParams = new URLSearchParams(window.location.search);
const transactionIdExt = queryParams.get('transaction_id');
const {user} = useContext(UserContext);
const [payment, setPayment] = useState([]);
const [wait, setWait] = useState(false);
const [validated, setValidated] = useState(false);
const [formData, setFormData] = useState({});
const [isLoading, setIsLoading] = useState(false);
const [url, setUrl] = useState([]);
const [checkout, setCheckout] = useState([]);

const handleClose = () => {
  localStorage.removeItem('setProgress');
  return '/';
}

const handleNew = () => {
  localStorage.removeItem('setProgress');
  return '/invest';
}


useEffect(() => {
    
  const fetchData = async () => {
  try{
      const loginToken = localStorage.getItem('loginToken');
      Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
      const {data} = await Axios.post('transaction/get_status.php',{
      user_id:user.user_id,
      transaction_id_ext:transactionIdExt
      }).then(response => response.data).then((d) => setPayment(d));
      
      }
      catch(err){
          
          return {success:0, message:'Server Error!'};
      }
  };
fetchData();
    
}, []);



const handleRepay = async (e) => {
  e.preventDefault();
  setWait(true);
  const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    e.preventDefault();
    setValidated(true);

  if(!Object.values(formData).every(val => val.trim() !== '')){
      
      return;
  }

  const data = await createPayment(formData);
  if(data.success){
      e.target.reset();
      
      
  }
  else if(!data.success && data.message){
      
  }
  
}





const createPayment = async () => {

  
  try{
      setIsLoading(true);
      const loginToken = localStorage.getItem('loginToken');
      Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
      const {data} = await Axios.post('mollie/create_payment.php',{
        user_id: user.user_id,
        order_id: payment.transaction.order_id,
        order_id_ext: payment.transaction.ref,
        order_amount: payment.transaction.amount
        

      }).then(response => response.data).then((d) => {
        setCheckout(d);
        window.location.replace(d.checkoutUrl); // Move the redirect code here
      });
    
  
      setIsLoading(false);
      
     // setErrMsg(false);

     
      

  }
  catch(err){
      setWait(false);
      return {success:0, message:'Server Error!'};
  }

  
}

if (isLoading) {
  return <Redirecting />;
}

  


    return (
        
      <>
               <div id="dashboard">

<Topbar/>   
<Sidebar/> 
<div className="board">

<div className="card card-large">
     <div className="card-body">
     {payment.transaction && payment.transaction.status === 'paid' && (
      <>
     <h3 className="text-left">Uw inschrijving is compleet!</h3>
     <p>Elke eerste werkdag van de maand start het proces om inschrijvingen om te zetten in participaties. Dit kan tot 28 werkdagen duren. U ontvangt bericht van ons zodra uw inschrijving is geaccepteerd.</p>
  <div className="spacer s mt-3"></div>

  <div className="card-btn d-flex">
                <Form className="full ml-auto mt-3" onSubmit={handleClose}>
                            
                <button
              
                className="btn mt-3 ml-auto float-end btn-link" 
                type="submit"

                >
                Afsluiten
                </button>
               </Form>
               <Form className="full ml-auto mt-3" onSubmit={handleNew}>
                            
                <button
              
                className="btn mt-3 ml-auto float-end btn-primary" 
                type="submit"

                >
                Nog een inschrijving doen
                </button>
               </Form>
                 
              
    </div>
    </>
    )}

{payment.transaction && payment.transaction.status !== 'paid' && (
      <>
     <h3 className="text-left">Uw betaling is nog niet afgerond.</h3>
     <p>U kunt nogmaals proberen direct online te betalen. Wilt u liever later betalen? Geen probleem.</p>
  <div className="spacer s mt-3"></div>

  <div className="card-btn d-flex">
                <Form className="full ml-auto mt-3" onSubmit={handleClose}>
                            
                <button
              
                className="btn mt-3 ml-auto float-end btn-link" 
                type="submit"

                >
                Betaal later
                </button>
               </Form>
               <Form className="full ml-auto mt-3" onSubmit={handleRepay}>
                            
                <button
              
                className="btn mt-3 ml-auto float-end btn-primary" 
                type="submit"

                >
                Direct online betalen
                </button>
               </Form>
                 
              
    </div>
    </>
    )}
 
  </div>
    </div>
    


</div>

</div>
             
      </>    

        
    )
}

export default PaymentResponse;


